import './CoachingCard.css';
import {  memo, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import noPhotos from '../../assets/noPhotos.svg';
import { useDispatch } from 'react-redux';
import { setShowMessageObj, setUserPayTraining, setUserTraining, setUserTrainings } from '../../store/homeSlice';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { fetchGetData, fetchRequest, handleDeleteAssigned } from '../../helpers/Utils';
import ModalWindow from '../ModalWindow/ModalWindow';
import helpIcon from '../../assets/helpIcon.svg';
import ConfettiFinish from '../ConfettiFinish/ConfettiFinish';
import close4 from '../../assets/close4.svg';
import { ERROR, PENDING, SUCCESS } from '../../helpers/Config';

function CoachingCard({coaching, index, setIsPreloader=null}) {
    const language = useSelector(state => state.homeSlice.language);
    const userTrainings = useSelector(state => state.homeSlice.userTrainings);
    const user = useSelector(state => state.homeSlice.user);
    const is_app = useSelector(state => state.homeSlice.is_app);
    const [isModalCancel, setIsModalCancel] = useState(false);
    const [isModalPaid, setIsModalPaid] = useState(false);
    const [paidStatus, setPaidStatus] = useState('');
    const timeoutRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let token = localStorage.getItem('token-user')

    useEffect(() => {

        return () => {
            if (timeoutRef.current) {
                clearInterval(timeoutRef.current);
            }
        };
    }, [navigate]);
    
    useEffect(() => {
        handleCheckPaid()
    }, [coaching]);

    const handleIsDelete = (res) => {
        if (res.success) {
            dispatch(setUserTrainings([...userTrainings.filter(element => element._id !== coaching._id && element.assigned_id !== coaching.assigned_id)]))
            dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataDeleted'][language]}));
        } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
        }
        setIsModalCancel(false)
        dispatch(setUserPayTraining({})) 
        navigate(`/user/${user._id}`) 
    }

    const handleIsModalCancel = (boolean) => {
        if (boolean) {
            let data = {
            }
            handleDeleteAssigned(token, data, coaching.assigned_id, handleIsDelete)
        } else {
            setIsModalCancel(false)
        }
    }

    const handleDeleteTrening = (e) => {
        e.preventDefault();
        setIsModalCancel(true)
    }

    const handleSetUserTraining = (e) => {
        if (coaching.paid_status !== SUCCESS) {
            e.preventDefault();
        } else {
            dispatch(setUserTraining(coaching))
            navigate(`/user/${user._id}/${coaching._id}`)
        }
    }

    const handleCheckPaid = (isPending=false) => {
        if (coaching._id?.length && (coaching.paid_status === PENDING || isPending) && !timeoutRef.current) {
            timeoutRef.current = setInterval(() => {
                fetchGetData(`/stripe/checkout-session/${coaching.assigned_id}?token=${token}`)
                    .then(res => {
                        if (res?.success && res?.data?.length) {
                            if (res?.data === SUCCESS || res?.data === ERROR) {
                                setPaidStatus(res?.data);
                                setIsModalPaid(true);
                                dispatch(setUserTrainings([...userTrainings.map(el => el._id === coaching._id ? { ...el, paid_status: res?.data } : el)]));
                                clearInterval(timeoutRef.current);
                            }
                        }
                    })
            }, 5000)
        }
    }

    const handlePayment = async () => {
        if (setIsPreloader) {
            setIsPreloader(true);
        }
        let successUrl = `https://www.pro-coach.fit/redirect?is_paid=true&assigned_id=${coaching.assigned_id}&token=${token}&url=${encodeURIComponent('https://www.pro-coach.fit/user/' + user._id + '?is_paid=true&assigned_id=' + coaching.assigned_id + '&token=' + token)}`
        let cancelUrl = `https://www.pro-coach.fit/redirect?url=${encodeURIComponent('https://www.pro-coach.fit/user/' + user._id)}`
        if (is_app) {
            successUrl = 'https://www.pro-coach.fit/user/' + user._id + '?is_paid=true&assigned_id=' + coaching.assigned_id + '&token=' + token
            cancelUrl = 'https://www.pro-coach.fit/user/' + user._id
        }
        let data = {
            assigned_id: coaching.assigned_id,
            amount: coaching.price*100,
            currency: 'usd',
            stripe_id: coaching.stripe_id,
            successUrl: successUrl, 
            cancelUrl: cancelUrl, 
        }
        fetchRequest('POST', `/stripe/create-checkout-session?token=${token}`, data)
            .then(res => {
                if (res?.success && res.data?.url?.length) {
                    window.open(res.data?.url, '_blank');
                    handleCheckPaid(true);
                    if (userTrainings?.length) {
                        dispatch(setUserTrainings([...userTrainings.map(el => el._id === coaching._id ? { ...el, paid_status: PENDING } : el)]));
                    }
                } else {    
                    dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}))
                }
                if (setIsPreloader) {
                    setIsPreloader(false);
                }
            })
    };

    const handlePayment2 = () => {
        if (coaching._id?.length) {
            let data = {
                user_id: user._id,
                training_id: coaching._id,
                coach_id: coaching?.coach_id,
                assigned_id: coaching?.assigned_id,
            }
            fetchRequest('POST', `/assigned/payload?token=${token}`, data)
                .then(res => {
                    if (res?.success) {
                        setIsModalPaid(true);
                        setPaidStatus(SUCCESS);
                        if (res.data?._id?.length) {
                            dispatch(setUserTrainings([...userTrainings.map(el => el._id === res.data?._id ? { ...res.data } : el)]));
                        }
                    } 
                })
        }
    };

    const handleIsPaid = (boolean) => {
        if (boolean) {
            
        }
        setIsModalPaid(false);
    };

    return (
        <div className='coaching-card'>
            {
                isModalPaid && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleIsPaid} 
                        notBtn={true}
                    >
                        {
                            paidStatus === SUCCESS ?
                                <>
                                    <svg className='time-progress__modal-img' width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask x="0" y="0" width="136" height="136">
                                            <rect width="136" height="136" fill="rgba(143, 127, 243, 1)"/>
                                        </mask>
                                        <g mask="url(#mask0_409_1912)">
                                            <path d="M57.1317 97.5248L105.458 49.1984L100.111 43.8513L57.1317 86.8307L35.5984 65.2974L30.2513 70.6444L57.1317 97.5248ZM68.0253 136C58.622 136 49.7814 134.216 41.5034 130.647C33.2254 127.078 26.0247 122.235 19.9011 116.117C13.7777 110 8.93004 102.806 5.35802 94.5355C1.78601 86.2652 0 77.4285 0 68.0253C0 58.622 1.78431 49.7814 5.35292 41.5034C8.92166 33.2254 13.7649 26.0247 19.8826 19.9011C26.0004 13.7777 33.1943 8.93004 41.4645 5.35803C49.7348 1.78601 58.5715 0 67.9747 0C77.378 0 86.2186 1.78431 94.4966 5.35292C102.775 8.92166 109.975 13.7649 116.099 19.8826C122.222 26.0004 127.07 33.1943 130.642 41.4645C134.214 49.7348 136 58.5715 136 67.9747C136 77.378 134.216 86.2186 130.647 94.4966C127.078 102.775 122.235 109.975 116.117 116.099C110 122.222 102.806 127.07 94.5355 130.642C86.2652 134.214 77.4285 136 68.0253 136ZM68 128.444C84.8741 128.444 99.1667 122.589 110.878 110.878C122.589 99.1667 128.444 84.8741 128.444 68C128.444 51.1259 122.589 36.8333 110.878 25.1222C99.1667 13.4111 84.8741 7.55556 68 7.55556C51.1259 7.55556 36.8333 13.4111 25.1222 25.1222C13.4111 36.8333 7.55556 51.1259 7.55556 68C7.55556 84.8741 13.4111 99.1667 25.1222 110.878C36.8333 122.589 51.1259 128.444 68 128.444Z" fill="rgba(143, 127, 243, 1)"/>
                                        </g>
                                    </svg>
                                    <h3 className='time-progress__modal-title'>{translations['congratulation'][language]}</h3>
                                    <div className='time-progress__modal-text'>{translations['haveNewTraining'][language]}</div>
                                    <div className='time-progress__modal-btn-wrap'>
                                        <button 
                                            className='mainBtnDark mainBtn--whidth' 
                                            onClick={() => setIsModalPaid(false)}
                                        >
                                            {translations['trainings'][language]}
                                        </button>
                                    </div>
                                    <ConfettiFinish />
                                </>
                                :
                                <>
                                    <img 
                                        className='time-progress__modal-img' 
                                        src={close4} 
                                        alt='img'
                                    />
                                    <h3 className='time-progress__modal-title'>{translations['sorryWrong'][language]}</h3>
                                    <div className='time-progress__modal-text'>{translations['tryAgainLater'][language]}</div>
                                    <div className='time-progress__modal-btn-wrap pay-view__modal-btn-wrap'>
                                        <button 
                                            className='mainBtnDark' 
                                            onClick={() => setIsModalPaid(false)}
                                        >
                                            {translations['tryAgain2'][language]}
                                        </button>
                                    </div>
                                </>
                        }
                    </ModalWindow>
            }
            {
                isModalCancel && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleIsModalCancel}
                    >
                        <div className='admin-trainings__dialogform-delete-wrap'>
                            <img 
                                className='admin-trainings__dialogform-delete-img' 
                                src={helpIcon} 
                                alt='img'
                            />
                            <div className='admin-trainings__dialogform-delete-text'>
                                <div>{translations['sureFinishedTraining'][language]}</div>
                                <div className='admin-trainings__dialogform-delete-text-name'>{coaching.name}</div>
                            </div>
                        </div>
                    </ModalWindow>
            }
            <div className='coaching-card__img-wrap'>
                <img 
                    className={`coaching-card__img ${coaching.paid_status === SUCCESS && 'coaching-card__img--paid' }`} 
                    src={coaching?.image?.length ? coaching?.image : noPhotos} 
                    alt='img'
                    onClick={handleSetUserTraining}
                />
                <div className='coaching-card__description'>
                    {
                        coaching.paid_status !== SUCCESS ?
                            <>
                                <div className='coaching-card__description-block'>{translations['duration'][language]} · {coaching.days?.length} {translations['days2'][language]}</div>
                                <div className='coaching-card__description--wrap'>
                                    <div className='coaching-card__description-block'>{translations['finish2'][language]} · {coaching.days_expire} {translations['days2'][language]}</div>
                                    <div className='coaching-card__description-block'>{translations['price'][language]} · ${coaching.price}</div>
                                </div>
                            </>
                            :
                            <div className='coaching-card__description--wrap'>
                                <div className='coaching-card__description-block'>{translations['expire'][language]} · {coaching.days_expire} {translations['days2'][language]}</div>
                                <div className='coaching-card__description-block'>{translations['finish2'][language]} · {coaching.days_left} {translations['days2'][language]}</div>
                            </div>
                    }
                </div>
            </div>
            <div className='coaching-card__title--wrap'>
                <div className='coaching-card__title-wrap'>
                    <h3 className='coaching-card__title'>{coaching.name}</h3>
                    <h4 className='coaching-card__sub-title'>
                        <span>{translations['coach'][language]}:</span> 
                        <span>{coaching.coach}</span>
                    </h4>
                </div>
                {
                    coaching.paid_status !== SUCCESS ?
                        <div className='coaching-card__start-btn-wrap'>
                            {
                                coaching.paid_status === PENDING ? 
                                    <>
                                        <div className='coaching-card__hour'>
                                            <div className='loader coaching-card__loader'></div>
                                        </div>
                                        <div className='coaching-card__title'>{translations['paymentProcessing'][language]}</div>
                                    </>
                                    :
                                    <>
                                        <button 
                                            className='coaching-card__start-btn coaching-card__start-btn-cancel' 
                                            onClick={() => setIsModalCancel(true)}
                                        >
                                            {translations['cancel'][language]}
                                        </button>
                                        <button 
                                            className='coaching-card__start-btn coaching-card__start-btn-pay' 
                                            onClick={handlePayment}
                                        >
                                            <span>Опл. stripe</span>
                                        </button>
                                        <button 
                                            className='coaching-card__start-btn coaching-card__start-btn-pay' 
                                            onClick={handlePayment2}
                                        >
                                            <span>{translations['pay'][language]}</span>
                                        </button>
                                    </>
                            }
                        </div>
                        :
                        <div className='coaching-card__start-btn-wrap'>
                            <button 
                                className='coaching-card__start-btn coaching-card__start-btn-cancel' 
                                onClick={handleDeleteTrening}
                            >
                                {translations['cancel'][language]}
                            </button>
                            <NavLink 
                                className='coaching-card__start-btn' 
                                to={`${coaching._id}`} 
                                onClick={() => dispatch(setUserTraining(coaching))}
                            >
                                {translations['start'][language]}
                            </NavLink>
                        </div>
                }
            </div>
        </div>
    );
}

export default memo(CoachingCard);