import './AdminNotificationsView.css';
import { useEffect, useRef, useState } from 'react';
import { fetchGetData, handleCheckNewMessages, handleFormatDateWeek } from '../../helpers/Utils';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { useSelector } from 'react-redux';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import { setIsUnreadMessages, setNewNotifications, setSearchValue, setSeenNotifications } from '../../store/userSlice';
import InfiniteScroll from 'react-infinite-scroller';
import defaultAvatar from '../../assets/defaultAvatar.png';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import noMessagesImg from '../../assets/noMessagesImg.svg';
import cardTimeImg from '../../assets/cardTimeImg.svg';
import { NEWCARD } from '../../helpers/Config';

function AdminNotificationsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const is_readSearchParams = searchParams.get('is_read')
    const searchValueSearchParams = JSON.parse(searchParams.get('searchValue'))
    const searchValue = useSelector(state => state.userSlice.searchValue);
    const windowInnerWidth = useSelector(state => state.userSlice.windowInnerWidth);
    const coach = useSelector(state => state.userSlice.coach);
    const language = useSelector(state => state.userSlice.language);
    const newNotifications = useSelector(state => state.userSlice.newNotifications);
    const seenNotifications = useSelector(state => state.userSlice.seenNotifications);
    const [isRead, setIsRead] = useState(is_readSearchParams?.length ? JSON.parse(is_readSearchParams) : false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [page, setPage] = useState(0);
    const [isHasMore, setIsHasMore] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const scrollParentRef = useRef(null)
    const dispatch = useDispatch();
    const location = useLocation();
    let token = localStorage.getItem('token-admin')

    useEffect(() => {
        setIsNotFirstRender(true);
        handleGetNotifications(null, null, searchValueSearchParams?.length ? searchValueSearchParams : '');
        dispatch(setSearchValue(searchValueSearchParams?.length ? searchValueSearchParams : ''));

        return () => {
            dispatch(setSearchValue(''));
        }
    }, [])

    useEffect(() => {
        if (is_readSearchParams?.length && is_readSearchParams !== isRead) {
            setIsRead(JSON.parse(is_readSearchParams));
        }
        if (!is_readSearchParams?.length) {
            setIsRead(false);
        }
    }, [is_readSearchParams])

    useEffect(() => {
        if (isNotFirstRender && searchValueSearchParams === searchValue) {
            handleGetNotifications(0, null)
            setPage(0);
        }
    }, [searchValueSearchParams])

    useEffect(() => {
        if (isRead) {
            setNotifications(seenNotifications);
        } else {
            setNotifications(newNotifications);
        }
    }, [newNotifications, seenNotifications, isRead])
    
    const handleActiveTab = (boolean) => {
        dispatch(setSearchValue(''));
        setIsRead(boolean);
        newSearchParams.set('searchValue', JSON.stringify(''));
        newSearchParams.set('is_read', JSON.stringify(boolean));
        setSearchParams(newSearchParams);
        setPage(0);
        setIsHasMore(false);
        handleGetNotifications(0, boolean, '')
    }

    const handleLoadMore = () => {
        setIsHasMore(false);
        if (isHasMore) {
            handleGetNotifications(page + 1);
            setPage(page + 1);
        }
    }

    const handleGetNotifications = (newPage = null, boolean = null, startSearchValue = null) => {
        if (!notifications?.length) {
            setIsPreloader(true);
        }
        setIsHasMore(false);
        fetchGetData(`/notifications/all?is_updated=true&page=${newPage !== null ? newPage : page}&is_read=${boolean !== null ? boolean : isRead}&search=${startSearchValue !== null ? startSearchValue : searchValueSearchParams?.length ? searchValueSearchParams : ''}&token=${token}`)
            .then(res => {
                if (res?.success && res?.data) {
                    let newData = res.data?.items || [];
                    let updatedData = []
                    if ((boolean !== null && boolean) || (boolean === null && isRead)) {
                        updatedData = newPage ? [...seenNotifications, ...newData] : newData;
                        dispatch(setSeenNotifications(updatedData));
                    } else {
                        updatedData = newPage ? [...newNotifications, ...newData] : newData;
                        dispatch(setNewNotifications(updatedData));
                    }
                    setIsHasMore(res.data.is_has_more ? true : false);
                    setArchivedCount(res.data?.archived_count ? res.data?.archived_count : 0)
                    setTotalCount((res.data?.count && totalCount <= res.data?.count) ? res.data?.count : 0)
                    if (!res.data.is_has_more) {
                        handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessages(res)));
                    }
                } 
                setIsPreloader(false)
            })
    }

    return (
        <div className="admin-notifications-wrap">
            <PageMeta {...pageMeta['AdminNotificationsView']} />
            {
                isPreloader && <PreloaderCustom />
            }
            <div className="admin-notifications">
                <div className='admin-notifications__btn--wrap'>
                    <div className='admin-notifications__count-wrap'>
                        <div className='admin-notifications__count'>{isRead ? archivedCount : totalCount}</div>
                        <div className='admin-notifications__count-text'>
                            {isRead ? translations['seenMessage'][language] : translations['newMessage'][language]}
                        </div>
                    </div>
                    <div className='admin-notifications__btn-wrap'>
                        <DoubleButtons 
                            isActive={!isRead}
                            leftBtnText={translations['new'][language]}
                            rightBtnText={translations['seen'][language]}
                            onClickLeftBtn={() => handleActiveTab(false)} 
                            onClickRightBtn={() => handleActiveTab(true)}
                        />
                    </div>
                    {/* <div className='admin-notifications__price-wrap'>
                    </div> */}
                </div>

                <div className='admin-notifications__content-items-wrap'>
                    <div 
                        className={`admin-notifications__content-items ${location.pathname.includes('notifications') ? 'admin-notifications__content-items--notifications' : ''}`} 
                        ref={scrollParentRef}
                    >
                        {
                            !!notifications?.length ?
                                <InfiniteScroll
                                    pageStart={0}
                                    useWindow = { false } 
                                    getScrollParent={() => scrollParentRef.current}
                                    loadMore={handleLoadMore}
                                    hasMore={isHasMore}
                                    className='admin-notifications__content-item-wrap'
                                >
                                    {
                                        notifications.map((el, i) => (
                                            <div className='admin-notifications__content-item' key={i}>
                                                {
                                                    windowInnerWidth < 640 ?
                                                        <div className='admin-notifications__mobile-card-title--wrap'>
                                                            <div className='admin-notifications__mobile-card-title-wrap'>
                                                                <div className={`admin-notifications__mobile-card-title ${isRead ? 'opacityContainer-5' : ''}`}><b>{el.user_name}</b> <span>{!!el.type?.length ? translations[el.type][language] : ''} {!!el?.text?.length ? el.text : ''}</span></div>
                                                                <div className='admin-notifications__mobile-card-days-wrap'>
                                                                    <span className={`admin-notifications__mobile-card-days ${isRead ? 'opacityContainer-5' : ''}`}>{handleFormatDateWeek(el.create_at, language)}</span>
                                                                    {
                                                                        el.type === NEWCARD && 
                                                                            <NavLink 
                                                                                className='mainBtnDark modal-notifications__content-item-btn-add'
                                                                                to={windowInnerWidth < 640 ? `/auth/${coach._id}/profile?is_info=false` : `/auth/${coach._id}/bills`}

                                                                            >
                                                                                {translations['add'][language]}
                                                                            </NavLink>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='admin-notifications__content-item-img-wrap'>
                                                                {
                                                                    el.type === NEWCARD ?
                                                                        <img 
                                                                            className='modal-notifications__content-item-img' 
                                                                            src={cardTimeImg} 
                                                                            alt='img'
                                                                        />
                                                                        :
                                                                        <img 
                                                                            className='admin-notifications__content-item-img' 
                                                                            src={el.image?.length ? el.image : defaultAvatar} 
                                                                            alt='img'
                                                                        />
                                                                }
                                                                {
                                                                    (!el.image?.length && !!el.user_name?.length && el.type !== NEWCARD) && 
                                                                        <div className='admin-notifications__content-item-img-text'>{el.user_name.split(' ').map(word => word[0] + (word[1] || '')).join('')}</div>
                                                                }
                                                            </div>
                                                            <div className={`admin-notifications__content-item-text-wrap ${isRead ? 'admin-notifications__mobile-card-title-wrap--archived' : ''}`}>
                                                                <div className='admin-notifications__content-item-text'>
                                                                    <b>{el.user_name}</b> <span>{!!el.type?.length ? translations[el.type][language] : ''} {!!el?.text?.length ? el.text : ''}</span>   
                                                                </div>
                                                                <div className='admin-notifications__content-item-date'>{handleFormatDateWeek(el.create_at, language)}</div>
                                                            </div>
                                                            {
                                                                el.type === NEWCARD && 
                                                                    <NavLink 
                                                                        className='mainBtnDark modal-notifications__content-item-btn-add'
                                                                        to={windowInnerWidth < 640 ? `/auth/${coach._id}/profile?is_info=false` : `/auth/${coach._id}/bills`}
                                                                    >
                                                                        {translations['goToBilling'][language]}
                                                                    </NavLink>
                                                            }
                                                        </> 
                                                }
                                            </div>
                                        ))
                                    }
                                </InfiniteScroll>
                                :
                                <>
                                    {
                                        !!searchValueSearchParams?.length ?
                                            <div className='admin-all-plans__empty-wrap admin-all-plans__empty'>{translations['noSearchResult'][language]}</div>
                                            :
                                            <div className='admin-all-plans__empty--wrap'>
                                                <img 
                                                    className='admin-all-plans__empty-img' 
                                                    src={noMessagesImg} 
                                                    alt='empty' 
                                                />
                                                <div className='admin-all-plans__empty-wrap admin-all-plans__empty'>{translations['messagesListEmpty'][language]}</div>
                                            </div>
                                    }
                                </>
                        }
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default AdminNotificationsView;