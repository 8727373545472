import './NotificationsView.css';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { fetchGetData, handleCheckNewMessages, handleFormatDateWeek } from '../../helpers/Utils';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { useSelector } from 'react-redux';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { setIsUnreadMessagesUser, setNewNotifications, setSeenNotifications } from '../../store/homeSlice';
import noMessagesImg from '../../assets/noMessagesImg.svg';

function NotificationsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const is_readSearchParams = searchParams.get('is_read')
    const windowInnerWidth = useSelector(state => state.homeSlice.windowInnerWidth);
    const language = useSelector(state => state.homeSlice.language);
    const seenNotifications = useSelector(state => state.homeSlice.seenNotifications);
    const newNotifications = useSelector(state => state.homeSlice.newNotifications);
    const [isRead, setIsRead] = useState(is_readSearchParams?.length ? JSON.parse(is_readSearchParams) : false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [userNotifications, setUserNotifications] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [page, setPage] = useState(0);
    const [isHasMore, setIsHasMore] = useState(false);
    const scrollParentRef = useRef(null)
    const dispatch = useDispatch();
    let token = localStorage.getItem('token-user')

    useEffect(() => {
        handleGetNotifications(null, null)
    }, [])
    
    useEffect(() => {
        if (is_readSearchParams?.length && is_readSearchParams !== isRead) {
            setIsRead(JSON.parse(is_readSearchParams));
        }
        if (!is_readSearchParams?.length) {
            setIsRead(false);
        }
    }, [is_readSearchParams])

    useEffect(() => {
        if (isRead) {
            setUserNotifications(seenNotifications);
        } else {
            setUserNotifications(newNotifications);
        }
    }, [newNotifications, seenNotifications, isRead])
    
    const handleActiveTab = (boolean) => {
        setIsRead(boolean);
        newSearchParams.set('is_read', JSON.stringify(boolean));
        setSearchParams(newSearchParams);
        setPage(0);
        setIsHasMore(false);
        handleGetNotifications(0, boolean)
    }

    const handleLoadMore = () => {
        setIsHasMore(false);
        if (isHasMore) {
            handleGetNotifications(page + 1);
            setPage(page + 1);
        }
    }

    const handleGetNotifications = (newPage = null, boolean = null) => {
        if (!userNotifications?.length) {
            setIsPreloader(true);
        }
        fetchGetData(`/notifications/all?is_updated=true&page=${newPage !== null ? newPage : page}&is_read=${boolean !== null ? boolean : isRead}&search=&token=${token}`)
            .then(res => {
                if (res?.success && res?.data) {
                    let newData = res.data?.items || [];
                    let updatedData = []
                    if ((boolean !== null && boolean) || (boolean === null && isRead)) {
                        updatedData = newPage ? [...seenNotifications, ...newData] : newData;
                        dispatch(setSeenNotifications(updatedData));
                    } else {
                        updatedData = newPage ? [...newNotifications, ...newData] : newData;
                        dispatch(setNewNotifications(updatedData));
                    }
                    setIsHasMore(res.data.is_has_more ? true : false);
                    setArchivedCount(res.data?.archived_count ? res.data?.archived_count : 0)
                    setTotalCount((res.data?.count && totalCount <= res.data?.count) ? res.data?.count : 0)
                    if (!res.data.is_has_more) {
                        handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessagesUser(res)));
                    }
                } 
                setIsPreloader(false)
            })
    }

    return (
        <div className="notifications-view-wrap">
            <PageMeta {...pageMeta['AdminNotificationsView']} />
            {
                isPreloader && 
                    <PreloaderCustom 
                        newStyle={{height: `100vh`, left: `0`, top: '0'}} 
                        isNewStyleLoader={true}
                    />
            }
            <div className="notifications-view container">
                <div className='notifications-view__btn--wrap'>
                    <div className='notifications-view__count-wrap'>
                        <div className='notifications-view__count'>{isRead ? archivedCount : totalCount}</div>
                        <div className='notifications-view__count-text'>
                            {!isRead ? translations['newMessage'][language] : translations['seenMessage'][language]}
                        </div>
                    </div>
                    <div className='notifications-view__btn-wrap'>
                        <DoubleButtons 
                            isActive={!isRead}
                            leftBtnText={translations['new'][language]}
                            rightBtnText={translations['seen'][language]}
                            onClickLeftBtn={() => handleActiveTab(false)} 
                            onClickRightBtn={() => handleActiveTab(true)}
                        />
                    </div>
                </div>

                <div className='notifications-view__content-items-wrap'>
                    <div className='notifications-view__content-items' ref={scrollParentRef}>
                        {
                            !!userNotifications?.length ?
                                <InfiniteScroll
                                    pageStart={0}
                                    useWindow = { false } 
                                    getScrollParent={() => scrollParentRef.current}
                                    loadMore={handleLoadMore}
                                    hasMore={isHasMore}
                                    className='admin-notifications__content-item-wrap'
                                >
                                    {
                                        userNotifications.map((el, i) => (
                                            <div className='notifications-view__content-item' key={i}>
                                                {
                                                    windowInnerWidth < 640 ?
                                                        <div className='notifications-view__mobile-card-title--wrap'>
                                                            <div className={`notifications-view__mobile-card-title-wrap ${isRead ? 'notifications-view__mobile-card-title-wrap--archived' : ''}`}>
                                                                <div className='notifications-view__mobile-card-title'><b>{el.coach_name}</b> <span>{!!el.type?.length ? translations[el.type][language] : ''} {!!el?.text?.length ? el.text : ''}</span></div>
                                                                <div className='notifications-view__mobile-card-days'>{handleFormatDateWeek(el.create_at, language)}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <dov className={`notifications-view__content-item-text-wrap ${isRead ? 'notifications-view__mobile-card-title-wrap--archived' : ''}`}>
                                                            <div className='notifications-view__content-item-text'>
                                                                <b>{el.coach_name}</b> <span>{!!el.type?.length ? translations[el.type][language] : ''} {!!el?.text?.length ? el.text : ''}</span>   
                                                            </div>
                                                            <div className='notifications-view__content-item-date'>{handleFormatDateWeek(el.create_at, language)}</div>
                                                        </dov>
                                                }
                                            </div>
                                        ))
                                    }
                                </InfiniteScroll> 
                                :
                                <div className='admin-all-plans__empty--wrap'>
                                    <img 
                                        className='admin-all-plans__empty-img' 
                                        src={noMessagesImg} 
                                        alt='empty' 
                                    />
                                    <div className='admin-all-plans__empty-wrap'>{translations['messagesListEmpty'][language]}</div>
                                </div>
                        }
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default NotificationsView;