import './RedirectView.css';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function RedirectView() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        navigate(`/${location.search}`)
    }, [])


    return (
        <div className="redirect-view">
        </div>
    );
}

export default RedirectView;