import './AvailableBloc.css';
import { memo } from 'react';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';
import flagUSA from '../../assets/flag/flagUSA.svg';
import flagUK from '../../assets/flag/flagUK.svg';
import flagMexico from '../../assets/flag/flagMexico.svg';
import flagJapan from '../../assets/flag/flagJapan.svg';
import flagAustralia from '../../assets/flag/flagAustralia.svg';
import flagBulgaria from '../../assets/flag/flagBulgaria.svg';
import flagCzech from '../../assets/flag/flagCzech.svg';
import flagFrance from '../../assets/flag/flagFrance.svg';
import flagGreece from '../../assets/flag/flagGreece.svg';
import flagLuxembourg from '../../assets/flag/flagLuxembourg.svg';
import flagNetherlands from '../../assets/flag/flagNetherlands.svg';
import flagPoland from '../../assets/flag/flagPoland.svg';
import flagSlovakia from '../../assets/flag/flagSlovakia.svg';
import flagSweden from '../../assets/flag/flagSweden.svg';
import flagAustria from '../../assets/flag/flagAustria.svg';
import flagCanada from '../../assets/flag/flagCanada.svg';
import flagDenmark from '../../assets/flag/flagDenmark.svg';
import flagGermany from '../../assets/flag/flagGermany.svg';
import flagHongKong from '../../assets/flag/flagHongKong.svg';
import flagIreland from '../../assets/flag/flagIreland.svg';
import flagLatvia from '../../assets/flag/flagLatvia.svg';
import flagMalaysia from '../../assets/flag/flagMalaysia.svg';
import flagNewZealand from '../../assets/flag/flagNewZealand.svg';
import flagPortugal from '../../assets/flag/flagPortugal.svg';
import falgSlovenia from '../../assets/flag/falgSlovenia.svg';
import flagSwitzerland from '../../assets/flag/flagSwitzerland.svg';
import flagBelgium from '../../assets/flag/flagBelgium.svg';
import flagCroatia from '../../assets/flag/flagCroatia.svg';
import flagEstonia from '../../assets/flag/flagEstonia.svg';
import flagHungary from '../../assets/flag/flagHungary.svg';
import flagItaly from '../../assets/flag/flagItaly.svg';
import flagLiechtenstein from '../../assets/flag/flagLiechtenstein.svg';
import flagMalta from '../../assets/flag/flagMalta.svg';
import flagRomania from '../../assets/flag/flagRomania.svg';
import flagThailand from '../../assets/flag/flagThailand.svg';
import flagBrazil from '../../assets/flag/flagBrazil.svg';
import flagCyprus from '../../assets/flag/flagCyprus.svg';
import flagFinland from '../../assets/flag/flagFinland.svg';
import flagGibraltar from '../../assets/flag/flagGibraltar.svg';
import flagLithuanian from '../../assets/flag/flagLithuanian.svg';
import flagNorway from '../../assets/flag/flagNorway.svg';
import flagSingapore from '../../assets/flag/flagSingapore.svg';
import flagSpain from '../../assets/flag/flagSpain.svg';
import flagUnitedArabEmirates from '../../assets/flag/flagUnitedArabEmirates.svg';

function AvailableBloc() {
    const language = useSelector(state => state.homeSlice.language);

    const flagArr = [
        {
            image: flagAustralia,
            name: 'Australia',
        },
        {
            image: flagBulgaria,
            name: 'Bulgaria',
        },
        {
            image: flagCzech,
            name: 'Czech Republic',
        },
        {
            image: flagFrance,
            name: 'France',
        },
        {
            image: flagGreece,
            name: 'Greece',
        },
        {
            image: flagLuxembourg,
            name: 'Luxembourg',
        },
        {
            image: flagNetherlands,
            name: 'Netherlands',
        },
        {
            image: flagPoland,
            name: 'Poland',
        },
        {
            image: flagSlovakia,
            name: 'Slovakia',
        },
        {
            image: flagSweden,
            name: 'Sweden',
        },
        {
            image: flagUK,
            name: 'United Kingdom',
        },
        {
            image: flagAustria,
            name: 'Austria',
        },
        {
            image: flagCanada,
            name: 'Canada',
        },
        {
            image: flagDenmark,
            name: 'Denmark ',
        },
        {
            image: flagGermany,
            name: 'Germany',
        },
        {
            image: flagHongKong,
            name: 'Hong Kong',
        },
        {
            image: flagIreland,
            name: 'Ireland',
        },
        {
            image: flagLatvia,
            name: 'Latvia',
        },
        {
            image: flagMalaysia,
            name: 'Malaysia',
        },
        {
            image: flagNewZealand,
            name: 'New Zealand',
        },
        {
            image: flagPortugal,
            name: 'Portugal',
        },
        {
            image: falgSlovenia,
            name: 'Slovenia',
        },
        {
            image: flagSwitzerland,
            name: 'Switzerland',
        },
        {
            image: flagUSA,
            name: 'USA',
        },
        {
            image: flagBelgium,
            name: 'Belgium',
        },
        {
            image: flagCroatia,
            name: 'Croatia',
        },
        {
            image: flagEstonia,
            name: 'Estonia',
        },
        {
            image: flagHungary,
            name: 'Hungary',
        },
        {
            image: flagItaly ,
            name: 'Italy',
        },
        {
            image: flagLiechtenstein,
            name: 'Liechtenstein',
        },
        {
            image: flagMalta,
            name: 'Malta',
        },
        {
            image: flagRomania,
            name: 'Romania',
        },
        {
            image: flagThailand,
            name: 'Thailand',
        },
        {
            image: flagBrazil,
            name: 'Brazil',
        },
        {
            image: flagCyprus,
            name: 'Cyprus',
        },
        {
            image: flagFinland,
            name: 'Finland',
        },
        {
            image: flagGibraltar,
            name: 'Gibraltar',
        },
        {
            image: flagJapan,
            name: 'Japan',
        },
        {
            image: flagLithuanian,
            name: 'Lithuanian',
        },
        {
            image: flagMexico,
            name: 'Mexico',
        },
        {
            image: flagNorway,
            name: 'Norway',
        },
        {
            image: flagSingapore,
            name: 'Singapore',
        },
        {
            image: flagSpain,
            name: 'Spain',
        },
        {
            image: flagUnitedArabEmirates,
            name: 'United Arab Emirates',
        },
    ]

    return (
		<div className='available-bloc'>
            <h3 className='available-bloc__title'>{translations['available'][language]}</h3>
            <div className='available-bloc__flags'>
                {
                    !!flagArr?.length && flagArr.map((item, index) => (
                        <div className='available-bloc__flag' key={index}>
                            <img className='available-bloc__flag-img' src={item.image} alt='flag' />
                            <p className='available-bloc__flag-name'>{item.name}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default memo(AvailableBloc);