export const translations = {
    'addExercise': { 
        'en': 'Add exercise',
        'ua': 'Додати вправу',
    },
    'addTraining': {
        'en': 'Add training',
        'ua': 'Додати тренування',
    },
    'addBodybuilder': {
        'en': 'Add bodybuilder',
        'ua': 'Додати бодібілдера',
    },
    'addUser': {
        'en': 'Send invitation',
        'ua': 'Надіслати запрошення',
    },
    'notFoundUser': {
        'en': 'User not found',
        'ua': 'Користувач не знайдено',
    },
    'addOffer': {
        'en': 'Send offer',
        'ua': 'Запропонувати',
    },
    'add': {
        'en': '+Add',
        'ua': 'Додати',
    },
    'receipt': {
        'en': 'Receipt',
        'ua': 'Рахунок',
    },
    'change': {
        'en': 'Change',
        'ua': 'Змінити',
    },
    'info': {
        'en': 'Info',
        'ua': 'Інформація',
    },
    'billing': {
        'en': 'Billing',
        'ua': 'Рахунки',
    },
    'goToBilling': {
        'en': 'go to billing',
        'ua': 'Перейти до рахунків',
    },
    'transactions': {
        'en': 'Transactions',
        'ua': 'Транзакції',
    },
    'downloadwExcel': {
        'en': 'Downloadw excel',
        'ua': 'Завантажити excel',
    },
    'noTransactions': {
        'en': 'No transactions yet',
        'ua': 'Транзакцій ще немає',
    },
    'goToStripe': {
        'en': 'Go to Stripe',
        'ua': 'Перейти до Stripe',
    },
    'default': {
        'en': 'Default',
        'ua': 'Вибрана',
    },
    'expiresOn': {
        'en': 'Expires on',
        'ua': 'Термін дії закінчується',
    },
    'logOut': {
        'en': 'Log out',
        'ua': 'Вийти',
    },
    'close': {
        'en': 'Close',
        'ua': 'Закрити',
    },
    'confirm': {
        'en': 'Confirm',
        'ua': 'Підтвердити',
    },
    'cancel': {
        'en': 'Cancel',
        'ua': 'Скасувати',
    },
    'accept': {
        'en': 'Accept',
        'ua': 'Прийняти',
    },
    'run': {
        'en': 'Run',
        'ua': 'Розпочати',
    },
    'more': {
        'en': 'More',
        'ua': 'Більше',
    },
    'showAll': {
        'en': 'Show all',
        'ua': 'Показати всі',
    },
    'edit': {
        'en': 'Edit',
        'ua': 'Ред.',
    },
    'delete': {
        'en': 'Delete',
        'ua': 'Вид.',
    },
    'copy': {
        'en': 'Copy',
        'ua': 'Коп.',
    },
    'next': {
        'en': 'Next step',
        'ua': 'Наступний крок',
    },
    'nextDay': {
        'en': 'Next day',
        'ua': 'Наступний день',
    },
    'next2': {
        'en': 'Next',
        'ua': 'Наступний',
    },
    'back': {
        'en': 'Back',
        'ua': 'Назад',
    },
    'save': {
        'en': 'Save',
        'ua': 'Зберегти',
    },
    'complete': {
        'en': 'Complete',
        'ua': 'Завершити',
    },
    'continue': {
        'en': 'Continue',
        'ua': 'Продовжити',
    },
    'finish': {
        'en': 'Finish',
        'ua': 'Завершити',
    },
    'finish2': {
        'en': 'Finish',
        'ua': 'Закінчиться',
    },
    'daysAmount': {
        'en': 'Days amount',
        'ua': 'Кількість днів',
    },
    'daysToFinish': {
        'en': 'Time for execution',
        'ua': 'Час на виконання',
    },
    'notPaid': {
        'en': 'Not paid',
        'ua': 'Не оплачено',
    },
    'expireIn': {
        'en': 'Expire in',
        'ua': 'Залишилось',
    },
    'expire': {
        'en': 'Expire',
        'ua': 'Закінчення',
    },
    'training': {
        'en': 'Training',
        'ua': 'Тренування',
    },
    'trainings': {
        'en': 'Trainings',
        'ua': 'Тренування',
    },
    'errorTrening': {
        'en': 'Select training',
        'ua': 'Виберіть тренування',
    },
    'trainingPrice': {
        'en': 'Training price',
        'ua': 'Вартість тренування',
    },
    'errorTrening1': {
        'en': 'Training already exists',
        'ua': 'Тренування вже існує',
    },
    'lastTraining': {
        'en': 'Last training',
        'ua': 'Останнє тренування',
    },
    'lastTrainings': {
        'en': 'Last trainings',
        'ua': 'Останні тренування',
    },
    'exerciseName': {
        'en': 'Exercise name',
        'ua': 'Назва вправи',
    },
    'exercises': {
        'en': 'Exercises',
        'ua': 'Вправи',
    },
    'exercises2': {
        'en': 'Exercises',
        'ua': 'Вправ',
    },
    'exercise': {
        'en': 'Exercise',
        'ua': 'Вправа',
    },
    'exercisesAmount': {
        'en': 'Exercises amount',
        'ua': 'Кількість вправ',
    },
    'editExercise': {
        'en': 'Edit exercise',
        'ua': 'Редагування',
    },
    'editRestTime': {
        'en': 'Edit rest time',
        'ua': 'Редагування',
    },
    'exerciseCreated': {
        'en': 'Exercise created',
        'ua': 'Вправу створено',
    },
    'exerciseUpdated': {
        'en': 'Exercise updated',
        'ua': 'Вправу оновлено',
    },
    'restTimeUpdated': {
        'en': 'Rest time updated',
        'ua': 'Відпочинок оновлено',
    },
    'restTimeCreated': {
        'en': 'Rest time created',
        'ua': 'Відпочинок створено',
    },
    'enterRestTime': {
        'en': 'Enter the rest time',
        'ua': 'Введіть час відпочинку',
    },
    'newMessage': {
        'en': 'New message',
        'ua': 'Нові повідомлення',
    },
    'send': {
        'en': 'Send',
        'ua': 'Відправити',
    },
    'seenMessage': {
        'en': 'Seen message',
        'ua': 'Переглянуті повідомлення',
    },
    'activeMessage': {
        'en': 'Active message',
        'ua': 'Активні повідомлення',
    },
    'downloadVideo': {
        'en': 'Video upload',
        'ua': 'Завантаження відео',
    },
    'active': {
        'en': 'Active',
        'ua': 'Активні',
    },
    'fieldRequired': {
        'en': 'This field is required',
        'ua': 'Це поле є обов\'язковим',
    },
    'mandatoryFieldsRegister': {
        'en': 'Mandatory fields are not filled',
        'ua': 'Обов\'язкові поля не заповнені',
    },
    'hoursMinutes': {
        'en': 'Enter hours and minutes',
        'ua': 'Введіть години і хвилини',
    },
    'name': {
        'en': 'Name',
        'ua': 'Ім\'я',
    },
    'enterName': {
        'en': 'Enter name',
        'ua': 'Введіть ім\'я',
    },
    'enterNewName': {
        'en': 'Enter new name',
        'ua': 'Введіть нову назву',
    },
    'firstName': {
        'en': 'First name',
        'ua': 'Ім\'я',
    },
    'lastName': {
        'en': 'Last name',
        'ua': 'Прізвище',
    },
    'enterFirstName': {
        'en': 'Enter your first name',
        'ua': 'Введіть своє ім\'я',
    },
    'enterLastName': {
        'en': 'Enter your last name',
        'ua': 'Введіть своє прізвище',
    },
    'location': {
        'en': 'City/Locality',
        'ua': 'Місто/Населений пункт',
    },
    'optional': {
        'en': 'optional',
        'ua': 'не обов\'язково',
    },
    'instractions': {
        'en': 'Instractions',
        'ua': 'Інструкція',
    },
    'tryAgain2': {
        'en': 'Try again',
        'ua': 'Спробувати ще',
    },
    'hello': {
        'en': 'Hello',
        'ua': 'Привіт',
    },
    'search': {
        'en': 'Search',
        'ua': 'Пошук',
    },
    'create': {
        'en': 'Create',
        'ua': 'Створити',
    },
    'bodybuilder': {
        'en': 'Bodybuilder',
        'ua': 'Бодібілдер',
    },
    'notifications': {
        'en': 'Notifications',
        'ua': 'Сповіщення',
    },
    'users': {
        'en': 'Users',
        'ua': 'Користувачі',
    },
    'profile': {
        'en': 'Profile',
        'ua': 'Профіль',
    },
    'start': {
        'en': 'Start',
        'ua': 'Розпочати',
    },
    'day': {
        'en': 'Day',
        'ua': 'День',
    },
    'days': {
        'en': 'days',
        'ua': 'дні',
    },
    'days2': {
        'en': 'days',
        'ua': 'днів',
    },
    'dayName': {
        'en': 'Day name',
        'ua': 'Назва дня',
    },
    'addDay': {
        'en': 'Сreate a day',
        'ua': 'Cтворити день',
    },
    'addDay2': {
        'en': 'Add a day',
        'ua': 'Додати день',
    },
    'dayWasAdded': {
        'en': 'The day was added',
        'ua': 'День додано',
    },
    'editDay': {
        'en': 'Edit day',
        'ua': 'Редагування деня',
    },
    'price': {
        'en': 'Training price',
        'ua': 'Вартість тренування',
    },
    'errorPhone': {
        'en': 'Incorrect phone number',
        'ua': 'Не вірно введений номер',
    },
    'phone': {
        'en': 'Phone,',
        'ua': 'Телефон,',
    },
    'pay': {
        'en': 'Pay',
        'ua': 'Оплатити',
    },
    'hour': {
        'en': 'hour',
        'ua': 'години',
    },
    'coach': {
        'en': 'Coach',
        'ua': 'Тренер',
    },
    'offer': {
        'en': 'Offer',
        'ua': 'Пропонувати',
    },
    'main': {
        'en': 'Main',
        'ua': 'Головна',
    },
    'all': {
        'en': 'All',
        'ua': 'Всі',
    },
    'duration': {
        'en': 'Duration',
        'ua': 'Тривалість',
    },
    'numberMinutes': {
        'en': 'Number of minutes',
        'ua': 'Кількість хвилин',
    },
    'minutes': {
        'en': 'minutes',
        'ua': 'хвилин',
    },
    'date': {
        'en': 'Date',
        'ua': 'Дата',
    },
    'period': {
        'en': 'Period',
        'ua': 'Період',
    },
    'amount': {
        'en': 'Amount',
        'ua': 'Сума',
    },
    'totalMinutes': {
        'en': 'Total minutes',
        'ua': 'Загальні хвилини',
    },
    'finishTraining': {
        'en': 'Finish training by',
        'ua': 'Завершити тренування до',
    },
    'login': {
        'en': 'Login',
        'ua': 'Зареєструватися',
    },
    'congratulation': {
        'en': 'Congratulation!',
        'ua': 'Вітаємо!',
    },
    'restTime': {
        'en': 'Rest time',
        'ua': 'Відпочинок',
    },
    'usersAmount': {
        'en': 'Bodybuilders Amount',
        'ua': 'Кількість Бодібілдерів',
    },
    'new': {
        'en': 'New',
        'ua': 'Нові',
    },
    'seen': {
        'en': 'Seen',
        'ua': 'Преглянуті',
    },
    'offerNewTraining': {
        'en': 'Offer new training',
        'ua': 'Запропонувати тренування',
    },
    'offerTraining': {
        'en': 'Offer training',
        'ua': 'Запропонувати тренування',
    },
    'zoom': {
        'en': 'Zoom',
        'ua': 'Збільшити',
    },
    'rotation': {
        'en': 'Rotation',
        'ua': 'Обернути',
    },
    'linkNotCorrect': {
        'en': 'Link is not correct',
        'ua': 'Посилання не коректне',
    },
    'cancelTraining': {
        'en': 'Cancel invitation',
        'ua': 'Скасувати запрошеення',
    },
    'activeTrainings': {
        'en': 'Active trainings',
        'ua': 'Активних тренуваннь',
    },
    'activeExercises': {
        'en': 'Active exercises',
        'ua': 'Активні вправи',
    },
    'activeBodybuilders': {
        'en': 'Active bodybuilders',
        'ua': 'Активні бодібілдери',
    },
    'archiveBodybuilders': {
        'en': 'Inactive bodybuilders',
        'ua': 'Неактивні бодібілдери',
    },
    'trainingName': {
        'en': 'Training name',
        'ua': 'Назва тренування',
    },
    'editTraining': {
        'en': 'Edit training',
        'ua': 'Оновити тренування',
    },
    'yourIncome': {
        'en': 'Your current income',
        'ua': 'Ваш поточний дохід',
    },
    'trainingUpdated': {
        'en': 'Training updated',
        'ua': 'Тренування оновлено',
    },
    'trainingCreated': {
        'en': 'Training created',
        'ua': 'Тренування створено',
    },
    'currentEmail': {
        'en': 'Current email',
        'ua': 'Поточний email',
    },
    'newEmail': {
        'en': 'New email',
        'ua': 'Новий email',
    },
    'changeEmail': {
        'en': 'Change email',
        'ua': 'Змінити email',
    },
    'resendMail': {
        'en': 'Resend email',
        'ua': 'Повторно відправити',
    },
    'errorEmail': {
        'en': 'Email is not correct',
        'ua': 'Не вірно введений email',
    },
    'errorEmail1': {
        'en': 'Enter email',
        'ua': 'Введіть email',
    },
    'errorEmail2': {
        'en': 'The email is already in use',
        'ua': 'Такий email вже використовується',
    },
    'errorEmail3': {
        'en': 'Please enter a valid email',
        'ua': 'Будь ласка, введіть коректний E-mail',
    },
    'enterUserEmail': {
        'en': 'Enter user email',
        'ua': 'Введіть email користувача',
    },
    'modalSentNotification': {
        'en': 'We sent notification to this bodybuilder',
        'ua': 'Ми надіслали сповіщення цьому користувачу',
    },
    'notRegisteredYet': {
        'en': 'Not registered yet',
        'ua': 'Не зареєстровано',
    },
    'sendInvatation': {
        'en': 'If you wish we can send an offer to this bodybuilder',
        'ua': 'Якщо ви хочете, ми можемо надіслати пропозицію до цього користувача',
    },
    'pleaseSelectPlan': {
        'en': 'Please select a training',
        'ua': 'Виберіть тренування',
    },
    'changeExpireDate': {
        'en': 'Change expire',
        'ua': 'Змінити дату',
    },
    'deleteTraining': {
        'en': 'Are you sure, you want to delete:',
        'ua': 'Ви впевнені, що хочете видалити:',
    },
    'deleteExercise': {
        'en': 'Delete this exercise?',
        'ua': 'Видалити вправу?',
    },
    'deleteRestTime': {
        'en': 'Delete this rest time?',
        'ua': 'Видалити цей відпочинок?',
    },
    'modalNewTrening': {
        'en': 'Duplicate exercise',
        'ua': 'Копіювати вправу',
    },
    'usersUseIt': {
        'en': 'in use',
        'ua': 'користується',
    },
    'duplicateThisDay': {
        'en': 'Duplicate this day',
        'ua': 'Дублювати цей день',
    },
    'deleteThisDay': {
        'en': 'Delete this day',
        'ua': 'Видалити цей день',
    },
    'chooseImage': {
        'en': 'Choose image',
        'ua': 'Виберіть картинку',
    },
    'errorVideoLink': {
        'en': 'Link to video is not correct',
        'ua': 'Посилання на відео введено невірно',
    },
    'linkToVideo': {
        'en': 'Link to the video',
        'ua': 'Посилання на відео',
    },
    'linkToImage': {
        'en': 'Link to the image',
        'ua': 'Посилання на картинку',
    },
    'byUrl': {
        'en': 'By link',
        'ua': 'Через посилання',
    },
    'downloadImage': {
        'en': 'Click here to download image',
        'ua': 'Натисніть, щоб завантажити картинку',
    },
    'downloadImage1': {
        'en': 'Click here to download the exercise image',
        'ua': 'Натисніть, щоб завантажити зображення вправи',
    },
    'downloadImage2': {
        'en': 'Click here to download the equipment image',
        'ua': 'Натисніть, щоб завантажити картинку тренажеру',
    },
    'selectDownloadVideo': {
        'en': 'Click here to download the exercise video',
        'ua': 'Натисніть, щоб завантажити відео вправи',
    },
    'downloadImage': {
        'en': 'Download image',
        'ua': 'Завантажити картинку',
    },
    'uploadFromComputer': {
        'en': 'Upload from your device',
        'ua': 'Завантажити з вашого пристрою',
    },
    'editImage': {
        'en': 'Edit image',
        'ua': 'Редагувати картинку',
    },
    'uploadVideo': {
        'en': 'Upload video',
        'ua': 'Завантажити відео',
    },
    'previewVideo': {
        'en': 'Preview video',
        'ua': 'Превью відео',
    },
    'uploadPreviewVideo': {
        'en': 'Upload preview video',
        'ua': 'Завантажити превью відео',
    },
    'areYouSure': {
        'en': 'Are you sure?',
        'ua': 'Ви впевнені?',
    },
    'selectExercise': {
        'en': 'Select exercise / rest time',
        'ua': 'Виберіть вправу / відпочинок',
    },
    'numberOfSets': {
        'en': 'Number of sets',
        'ua': 'Кількість підходів',
    },
    'numberOfRepetitions': {
        'en': 'Number of reps',
        'ua': 'Кількість повторень',
    },
    'timeForExercise': {
        'en': 'Time for set (min)',
        'ua': 'Час для підходу (хв.)',
    },
    'timeForRest': {
        'en': 'Time for rest',
        'ua': 'Час для перерви',
    },
    'mainVideo': {
        'en': 'Main video',
        'ua': 'Головне відео',
    },
    'watchVideo': {
        'en': 'Watch video',
        'ua': 'Переглянути відео',
    },
    'watch': {
        'en': 'Watch',
        'ua': 'Переглянути',
    },
    'mainVideoLink': {
        'en': 'Main video link',
        'ua': 'Посилання на головне відео',
    },
    'videoPreview': {
        'en': 'Video preview',
        'ua': 'Картинка для відео',
    },
    'noInstruction': {
        'en': 'Sorry, no instruction for this exercise',
        'ua': 'Вибачте, немає інструкцій для цієї вправи',
    },
    'equipment': {
        'en': 'Equipment',
        'ua': 'Тренажер',
    },
    'equipmentName': {
        'en': 'Equipment name',
        'ua': 'Назва тренажеру',
    },
    'equipmentImage': {
        'en': 'Equipment image',
        'ua': 'Картинка тренажеру',
    },
    'stepByStep': {
        'en': 'Step by step instruction',
        'ua': 'Покрокова інструкція',
    },
    'typicalMistakes': {
        'en': 'Typical mistakes',
        'ua': 'Типові помилки',
    },
    'askQrCode': {
        'en': 'No trainings yet',
        'ua': 'Ще немає тренувань',
    },
    'scanQrCode': {
        'en': 'Scan QR-code',
        'ua': 'Сканувати QR-код',
    },
    'goBack': {
        'en': 'Go back',
        'ua': 'Повернутись',
    },
    'goToTraining': {
        'en': 'Go to training',
        'ua': 'Перейти до тренування',
    },
    'pointCameraQr': {
        'en': 'Point the camera at the QR code',
        'ua': 'Наведіть камеру на QR-код',
    },
    'sorryWrong': {
        'en': 'Sorry, something went wrong.',
        'ua': 'Схоже, щось пішло не так.',
    },
    'finishedDay': {
        'en': 'You finished the day',
        'ua': 'Ви закінчили день',
    },
    'finishedTraining': {
        'en': 'You finished the training',
        'ua': 'Ви закінчили тренування',
    },
    'sureFinishedTraining': {
        'en': 'Are you sure you want to decline the training?',
        'ua': 'Ви впевнені, що хочете відмовитися від тренування?',
    },
    'finishDay': {
        'en': 'Are you sure you want to finish this day?',
        'ua': 'Ви впевнені, що хочете завершити цей день?',
    },
    'restartDay': {
        'en': 'Are you sure you want to restart this day?',
        'ua': 'Ви впевнені, що хочете почати спочатку цей день?',
    },
    'restartDayBtn': {
        'en': 'Restart day',
        'ua': 'Почати спочатку',
    },
    'finishDayBtn': {
        'en': 'Finish day',
        'ua': 'Завершити день',
    },
    'setsLeft': {
        'en': 'sets left',
        'ua': 'повторів',
    },
    'repetitionsLeft': {
        'en': 'repetitions left',
        'ua': 'кількість підходів',
    },
    'password': {
        'en': 'Password',
        'ua': 'Пароль',
    },
    'password1': {
        'en': 'password',
        'ua': 'пароль',
    },
    'changePassword': {
        'en': 'Change password',
        'ua': 'Змінити пароль',
    },
    'existingPassword': {
        'en': 'Existing password',
        'ua': 'Поточний пароль',
    },
    'newPassword': {
        'en': 'New password',
        'ua': 'Новий пароль',
    },
    'confirmNewPassword': {
        'en': 'Confirm new password',
        'ua': 'Підтвердіть новий пароль',
    },
    'confirmPassword': {
        'en': 'Confirm Password',
        'ua': 'Підтвердіть пароль',
    },
    'trainingsListEmpty': {
        'en': 'The trainings list is empty',
        'ua': 'Список тренувань порожній',
    },
    'userListEmpty': {
        'en': 'The user list is empty',
        'ua': 'Список користувачів порожній',
    },
    'noSearchResult': {
        'en': 'No search result',
        'ua': 'Нічого не знайдено',
    },
    'exercisesListEmpty': {
        'en': 'The exercises list is empty',
        'ua': 'Список вправ порожній',
    },
    'messagesListEmpty': {
        'en': 'No messages yet',
        'ua': 'Немає повідомлень',
    },
    'enterPassword': {
        'en': 'Enter password',
        'ua': 'Вкажіть пароль',
    },
    'errorPassword': {
        'en': 'Password must be at least 8 letters and numbers',
        'ua': 'Пароль повинен містити не менше 8 букв і цифр',
    },
    'errorPassword1': {
        'en': 'Incorrect password',
        'ua': 'Невірно введений пароль',
    },
    'errorPassword2': {
        'en': 'This password already in use',
        'ua': 'Такий пароль вже використовується',
    },
    'passwordsNotMatch': {
        'en': 'Passwords do not match',
        'ua': 'Паролі не співпадають',
    },
    'userUpdate': {
        'en': 'Please enter:',
        'ua': 'Будь ласка, введіть:',
    },
    'addTrainingMethods': {
        'en': 'To add training, use one of the methods',
        'ua': 'Щоб добавити тренування скористайтесь одним із способів',
    },
    'addTrainingMethods1': {
        'en': 'Scan the QR code using the app',
        'ua': 'Відскануйте QR-код використовуючи додаток.',
    },
    'addTrainingMethods2': {
        'en': 'Enter your email address on which you registered an account',
        'ua': 'Введіть свій email, на якому зареєстрували акаунт.',
    },
    'haveNewTraining': {
        'en': 'You have new training',
        'ua': 'Ви маєте нове тренування',
    },
    'paymentSuccessful': {
        'en': 'Payment was successful',
        'ua': 'Вітіємо оплата пройшла успішно',
    },
    'dataUpdated': {
        'en': 'The data has been updated',
        'ua': 'Дані були оновлені',
    },
    'dataDeleted': {
        'en': 'Successfully deleted',
        'ua': 'Успішно видалено',
    },
    'successfullyAdded': {
        'en': 'Successfully added',
        'ua': 'Успішно додано',
    },
    'mainPreview': {
        'en': 'Enter the link',
        'ua': 'Вставте посилання',
    },
    'mainPreviewImg': {
        'en': 'Main preview',
        'ua': 'Основна картинка',
    },
    'mandatoryFields': {
        'en': 'Mandatory fields: image, training name',
        'ua': 'Обов\'язкові поля: зображення та назва тренінгу',
    },
    'mandatoryFields2': {
        'en': 'Mandatory fields: video and training name and price',
        'ua': 'Обов\'язкові поля: відео та назва тренінгу і ціна',
    },
    'userAdded': {
        'en': 'User was added',
        'ua': 'Користувач доданий',
    },
    'lostTraining': {
        'en': 'Lost your training?',
        'ua': 'Втратили тренування?',
    },
    'trainingNext': {
        'en': 'What do you want to do next?',
        'ua': 'Що ви хочете зробити далі?',
    },
    'tryAgainLater': {
        'en': 'You can try again later',
        'ua': 'Ви можете спробувати ще раз пізніше',
    },
    'messageSent': {
        'en': 'Message sent to email',
        'ua': 'Повідомлення відправлено на пошту',
    },
    'canBeStarted': {
        'en': 'Training can be started through:',
        'ua': 'Тренування можна почати через:',
    },
    'errorExercise': {
        'en': 'Mandatory fields of the video, name, number of sets, number of reps, number of minutes',
        'ua': 'Обов\'язкові поля: відео, назва, кількість підходів, кількість повторень, кількість хвилин',
    },
    'errorCreate': {
        'en': 'Please try again later.',
        'ua': 'Будь-ласка спробуйте пізніше',
    },
    'downloadExcel': {
        'en': 'Are you sure, you want to download excel with transactions',
        'ua': 'Ви впевнені, що хочете завантажити Excel із транзакціями',
    },
    'paymentProcessing': {
        'en': 'Payment processing',
        'ua': 'Обробка платежу',
    },
    'downloadExcel': {
        'en': 'Download excel',
        'ua': 'Завантажити excel',
    },
    'noCards': {
        'en': 'No cards yet',
        'ua': 'Карток ще немає',
    },
    // New translations [Login/Register]
    'restoringPassword': {
        'en': 'Restoring password',
        'ua': 'Відновлення пароля',
    },
    'logIn': {
        'en': 'Log in',
        'ua': 'Увійти',
    },
    'login': {
        'en': 'Login',
        'ua': 'Увійти',
    },
    'createAccount': {
        'en': 'Create account',
        'ua': 'Створити акаунт',
    },
    'registration': {
        'en': 'Registration',
        'ua': 'Реєстрація',
    },
    'signUp': {
        'en': 'Sign up',
        'ua': 'Зареєструватися',
    },
    'startManaging': {
        'en': 'Start managing your trainings from today',
        'ua': 'Почніть керувати тренуваннями вже сьогодні',
    },
    'welcomeBack': {
        'en': 'Welcome back!',
        'ua': 'Ласкаво просимо назад!',
    },
    'loginToAccount': {
        'en': 'Don\'t you have an account?',
        'ua': 'У вас немає акаунту?',
    },
    'doYouHaveAccount': {
        'en': 'Do you have an account?',
        'ua': 'У вас є акаунт?',
    },
    'forgotPassword': {
        'en': 'Forgot password?',
        'ua': 'Забули пароль?',
    },
    'passwordRestored': {
        'en': 'Your password restored',
        'ua': 'Ваш пароль відновлено',
    },
    'passwordUpdated': {
        'en': 'Password updated',
        'ua': 'Пароль оновлено',
    },
    'emailUpdated': {
        'en': 'Email updated',
        'ua': 'Електронна пошта оновлена',
    },
    'restore': {
        'en': 'Restore',
        'ua': 'Відновити',
    },
    'businessType': {
        'en': 'Business type',
        'ua': 'Тип бізнесу',
    },
    'businessName': {
        'en': 'Business name',
        'ua': 'Назва бізнесу',
    },
    'businessCountry': {
        'en': 'Business country',
        'ua': 'Краіна бізнесу',
    },
    'stripeConfig': {
        'en': 'Stripe account configuration details',
        'ua': 'Деталі конфігурації облікового запису Stripe',
    },
    'corporationBusiness': {
        'en': 'For corporations, partnerships, or other incorporated entities.',
        'ua': 'Для корпорацій, партнерів або інших залежних економічних елементів.',
    },
    'individualBusiness': {
        'en': 'For individuals or sole proprietors. Use this when the connected account is owned by a single person without formal incorporation.',
        'ua': 'Для фізичних осіб або ФОП. Використовуйте це, якщо підключений обліковий запис належить одній особі без офіційної реєстрації.',
    },
    // errors
    'anErrorOccurred': {
        'en': 'An error occurred. Please try again later',
        'ua': 'Виникла помилка. Спробуйте, будь-ласка, пізніше',
    },
    'errorUseLink': {
        'en': 'Unable to use this image, please try another one.',
        'ua': 'Не вдається використати цю картинку, спробуйте, будь-ласка, іншу.',
    },
    'errorLoginEmail': {
        'en': 'No such user exists',
        'ua': 'Такого електронної пошти не існує',
    },
    'errorRegisterEmail': {
        'en': 'This email already exists',
        'ua': 'Така електронна пошта вже існує',
    },
    'errorName': {
        'en': 'This name already exists',
        'ua': 'Така назва вже існує',
    },
    // API notifications 
    'userPaidForTraining': {
        'en': 'user paid for training',
        'ua': 'користувач оплатив тренування',
    },
    'userDeclinedTraining': {
        'en': 'user declined training offer',
        'ua': 'користувач відмовився від тренування',
    },
    'userFinishedDay': {
        'en': 'user finished day',
        'ua': 'користувач завершив день',
    },
    'userFinishedTraining': {
        'en': 'user finished training',
        'ua': 'користувач завершив тренування',
    },
    'trainingIsPaid': {
        'en': 'thanks you for paying for the training',
        'ua': 'дякує тобі за оплату тренування',
    },
    'thanksForJoiningPlatform': {
        'en': 'Thanks for joining our platform',
        'ua': 'Дякуємо за приєднання до нашої платформи',
    },
    'youHaveNewTraining': {
        'en': 'You have new training',
        'ua': 'У вас нове тренування',
    },
    'congratulationsYouFinishedDay': {
        'en': 'congratulates you on finishing the day',
        'ua': 'вітає вас з завершенням дня',
    },
    'congratulationsYouFinishedTraining': {
        'en': 'congratulates you on finishing the training',
        'ua': 'вітає вас з завершенням тренування',
    },
    'addNewCard': {
        'en': 'Your card has expired, please add a new one',
        'ua': 'Ваша карта закінчується, будь ласка, додайте нову',
    },
    // Landing page
    'home': {
        'en': 'Home',
        'ua': 'Головна',
    },
    'product': {
        'en': 'Product',
        'ua': 'Продукт',
    },
    'aboutUs': {
        'en': 'About us',
        'ua': 'Про нас',
    },
    'terms': {
        'en': 'Terms',
        'ua': 'Правила',
    },
    'eula': {
        'en': 'EULA',
        'ua': 'EULA',
    },
    'privacyPolicy': {
        'en': 'Privacy Policy',
        'ua': 'Політика конфіденційності',
    },
    'transform': {
        'en': 'Transform',
        'ua': 'Перетворення',
    },
    'communicationBetween': {
        'en': 'communication between bodybuilder and coach with pro coach app',
        'ua': 'комунікація між бодібілдером і тренером з допомогою Pro Coach app',
    },
    'getStarted': {
        'en': 'Get started',
        'ua': 'Почати',
    },
    'our': {
        'en': 'Our',
        'ua': 'Наші',
    },
    'joinUs': {
        'en': 'Join us',
        'ua': 'Приєднайтесь до нас',
    },
    'activeBodybuildersText': {
        'en': 'With over 18,000 dedicated users, our Pro Coach app has transformed the fitness experience, providing bodybuilders with expert guidance even in their coach\'s absence. Through personalised video and text instructions, we empower users to pursue their goals in comfortable way, making Pro Coach their top choice for anytime.',
        'ua': 'Маючи понад 18 000 відданих користувачів, наш додаток Pro Coach змінив досвід занять фітнесом, надаючи бодібілдерам експертне керівництво навіть за відсутності тренера. За допомогою персоналізованих відео та текстових інструкцій ми даємо користувачам можливість досягати своїх цілей у зручний спосіб, роблячи Pro Coach їх найкращим вибором у будь-який час.',
    },
    'coaches': {
        'en': 'Coaches',
        'ua': 'Тренери',
    },
    'becomeCoach': {
        'en': 'Become a coach',
        'ua': 'Стати тренером',
    },
    'becomeCoachText': {
        'en': 'At Pro Coach, we boast a diverse community of over 1000 coaches. Our innovative platform bridges the gap between trainers and bodybuilders, providing personalised, expert guidance through a blend of video and text communication. Whether you\'re coaching locally or remotely, Pro Coach empowers you to reach and inspire clients like never before, helping them achieve their sport aspirations with confidence and precision. Join us and become part of a transformative movement in the sport industry.',
        'ua': 'У Pro Coach ми можемо похвалитися різноманітною спільнотою з понад 1000 тренерів. Наша інноваційна платформа доповнює розрив між тренерами та бодібілдерами, надаючи персоналізовані експертні рекомендації за допомогою поєднання відео та текстового спілкування. Незалежно від того, тренуєте ви локально чи дистанційно, Pro Coach дає вам змогу охоплювати та надихати клієнтів, як ніколи раніше, допомагаючи їм досягати своїх спортивних прагнень з упевненістю та точністю. Приєднуйтесь до нас і станьте частиною трансформаційного руху в спортивній індустрії.',
    },
    'becomeCoachText2': {
        'en': 'Discover the ultimate training experience with our coaches network of over 1000 trainers. With Pro Coach, you have the flexibility to set up the time / amount for each set and receive tailored video and text instructions, including information about equipment usage, for every workout, whether you\'re at home or in the gym. Our coaches have conducted over 6800 trainings in the app, empowering bodybuilders to confidently pursue their sport goals. Join us and revolutionise your sport journey today!',
        'ua': 'Відкрийте для себе неперевершені тренування з нашою мережею тренерів із понад 1000 тренерів. За допомогою Pro Coach ви можете гнучко встановлювати час/кількість для кожного підходу та отримувати індивідуальні відео та текстові інструкції, включаючи інформацію про використання обладнання, для кожного тренування, незалежно від того, чи ви вдома, чи в тренажерному залі. Наші тренери провели понад 6800 тренувань у додатку, надаючи бодібілдерам змогу впевнено досягати своїх спортивних цілей. Приєднуйтесь до нас і зробіть революцію у своїй спортивній подорожі сьогодні!',
    },
    'available': {
        'en': 'Available',
        'ua': 'Доступний',
    },
    'beLeader': {
        'en': 'Be the leader',
        'ua': 'Будьте лідером',
    },
    'forMore': {
        'en': 'For more',
        'ua': 'Для більшого',
    },
    'weGiveYou': {
        'en': 'we give you an opportunity to be the best',
        'ua': 'надаємо вам можливість бути найкращим',
    },
    'desktop': {
        'en': 'Desktop',
        'ua': 'Десктоп',
    },
    'mobile': {
        'en': 'Mobile',
        'ua': 'Мобільний',
    },
    'registrationStripe': {
        'en': 'The data in Stripe has been updated',
        'ua': 'Дані в Stripe оновлено',
    },
    'needRegisterStripe': {
        'en': 'You need to register in Stripe to be able to assign training',
        'ua': 'Для призначення тренування потрібно пройти Stripe реєстрацію',
    },
    'proCoachDesktop': {
        'en': 'Pro Coach Desktop is the ultimate tool for coaches and fitness professionals, offering a comprehensive admin panel to create and manage training programs for all users. With Pro Coach Desktop, you can easily design personalised training routines, add exercises tailored to each individual, and schedule rests to optimise recovery.',
        'ua': 'Pro Coach Desktop — це найкращий інструмент для тренерів і фітнес-професіоналів, який пропонує повну панель адміністратора для створення та керування програмами тренувань для всіх користувачів. За допомогою Pro Coach Desktop ви можете легко розробляти персоналізовані програми тренувань, додавати індивідуальні вправи та планувати відпочинок для оптимізації відновлення.',
    },
    'takeYourTraining': {
        'en': 'Take your training to the next level with Pro Coach Mobile, your all-in-one workout assistant. Designed for convenience and effectiveness, this app keeps you on track with your sport goals by showing all your active trainings, completed exercises, and those you\'ve yet to start.',
        'ua': 'Підніміть свої тренування на новий рівень за допомогою Pro Coach Mobile, вашого універсального помічника для тренувань. Створений для зручності та ефективності, цей додаток тримає вас на шляху до ваших спортивних цілей, показуючи всі ваші активні тренування, завершені вправи та ті, які ви ще не почали.',
    },
    'yourGoal': {
        'en': 'Your goal',
        'ua': 'Ваша ціль',
    },
    'createAnAccount': {
        'en': 'Create an account, purchase your personalized training program, and follow daily workouts with detailed instructions and equipment guidance on the Pro Coach app to track progress and achieve your sports goals.',
        'ua': 'Створіть обліковий запис, придбайте персоналізовану програму тренувань і виконуйте щоденні тренування з докладними інструкціями та вказівками щодо обладнання в додатку Pro Coach, щоб відстежувати прогрес і досягати своїх спортивних цілей.',
    },
    'easyToGet': {
        'en': 'Easy to get',
        'ua': 'Легко отримати',
    },
    'scanCode': {
        'en': 'Scan a QR code to access your training program, conveniently pay for it, and immediately begin your fitness journey with the Pro Coach app.',
        'ua': 'Відскануйте QR-код, щоб отримати доступ до своєї програми тренувань, зручно оплатити її та негайно почати свою фітнес-мандрівку за допомогою програми Pro Coach.',
    },
    'contactUs': {
        'en': 'Contact us',
        'ua': 'Зв\'яжіться з нами',
    },
    'yourName': {
        'en': 'Your name',
        'ua': 'Ваше ім\'я',
    },
    'yourMessage': {
        'en': 'Your message',
        'ua': 'Ваше повідомлення',
    },
    'weWant': {
        'en': 'We want to get to know you',
        'ua': 'Ми хочемо зрозуміти вас',
    },
    'infoBlockText': {
        'en': 'WeNoCode, an IT company known for creating user-friendly applications, has taken the fitness world by storm with their Pro Coach app. Pro Coach empowers people of all technical abilities to ditch the complexity of coding and focus on their fitness goals. This innovative app transforms your mobile device into a personalized coaching tool, making it easier than ever to take charge of your health and wellness journey.',
        'ua': 'WeNoCode, ІТ-компанія, відома створенням зручних програм, захопила світ фітнесу своїм додатком Pro Coach. Pro Coach дає змогу людям із будь-якими технічними здібностями відмовитися від складності програмування та зосередитися на своїх фітнес-цілях. Ця інноваційна програма перетворює ваш мобільний пристрій на персоналізований інструмент коучинга, завдяки чому вам легше, ніж будь-коли, контролювати свою подорож здоров’ям.',
    },
    'projectIdea': {
        'en': 'Project idea',
        'ua': 'Проектна ідея',
    },
    'ourProjectText1': {
        'en': 'Our project aims to revolutionize the sports coaching industry by leveraging innovative technology. We are developing a comprehensive platform that connects coaches and clients, offering personalized training programs, real-time progress tracking, and interactive communication tools.',
        'ua': 'Наш проект має на меті зробити революцію в індустрії спортивних тренерів, використовуючи інноваційні технології. Ми розробляємо комплексну платформу, яка об’єднує тренерів і клієнтів, пропонуючи персоналізовані навчальні програми, відстеження прогресу в реальному часі та інтерактивні засоби спілкування.',
    },
    'ourProjectText2': {
        'en': 'Our goal is to empower individuals to achieve their goals more effectively and efficiently, while also providing coaches with the tools they need to deliver exceptional service and support.',
        'ua': 'Наша мета полягає в тому, щоб надати людям змогу ефективніше та результативніше досягати своїх цілей, а також надати тренерам інструменти, необхідні для надання виняткових послуг і підтримки.',
    },
    // CommentsBloc
    'comments': {
        'en': 'comments',
        'ua': 'коментарі',
    },
    'comment1': {
        'en': 'It\'s incredible to see how it\'s empowered bodybuilders to stay on track and achieve their goals with confidence. The convenience and effectiveness of the app have made it an indispensable tool in my coaching arsenal.',
        'ua': 'Неймовірно бачити, як це допомогло бодібілдерам залишатися на правильному шляху та впевнено досягати своїх цілей. Зручність та ефективність додатку зробили його незамінним інструментом у моєму тренерському арсеналі.',
    },
    'comment2': {
        'en': 'It\'s incredible to see how it\'s empowered bodybuilders to stay on track and achieve their goals with confidence. The convenience and effectiveness of the app have made it an indispensable tool in my coaching arsenal.',
        'ua': 'Неймовірно бачити, як це допомогло бодібілдерам залишатися на правильному шляху та впевнено досягати своїх цілей. Зручність та ефективність додатку зробили його незамінним інструментом у моєму тренерському арсеналі.',
    },
    'comment3': {
        'en': 'It\'s incredible to see how it\'s empowered bodybuilders to stay on track and achieve their goals with confidence. The convenience and effectiveness of the app have made it an indispensable tool in my coaching arsenal.',
        'ua': 'Неймовірно бачити, як це допомогло бодібілдерам залишатися на правильному шляху та впевнено досягати своїх цілей. Зручність та ефективність додатку зробили його незамінним інструментом у моєму тренерському арсеналі.',
    },
    'comment4': {
        'en': 'It\'s incredible to see how it\'s empowered bodybuilders to stay on track and achieve their goals with confidence. The convenience and effectiveness of the app have made it an indispensable tool in my coaching arsenal.',
        'ua': 'Неймовірно бачити, як це допомогло бодібілдерам залишатися на правильному шляху та впевнено досягати своїх цілей. Зручність та ефективність додатку зробили його незамінним інструментом у моєму тренерському арсеналі.',
    },
    'comment5': {
        'en': 'I\'ve been in the fitness industry for 2 years, and Pro Coach is by far the most innovative app I\'ve come across. It\'s helped me keep my clients engaged and motivated with its personalised approach. Highly recommend it to any coach looking to take their training to the next level!',
        'ua': 'Я працюю у фітнес-індустрії 2 роки, і Pro Coach є, безперечно, найінноваційнішим додатком, з яким я стикався. Це допомогло мені зберегти зацікавленість і мотивацію моїх клієнтів завдяки індивідуальному підходу. Настійно рекомендую будь-якому тренеру, який хоче підняти свої тренування на новий рівень!',
    },
    'comment6': {
        'en': 'Pro Coach has truly revolutionised my coaching approach. The ability to provide personalised guidance, set workout timings, and offer equipment tips through the app has greatly enhanced my clients\' progress. It\'s a game-changer!',
        'ua': 'Pro Coach справді революціонізував мій тренерський підхід. Можливість надавати персоналізовані рекомендації, встановлювати розклад тренувань і пропонувати підказки щодо обладнання через додаток значно покращила прогрес моїх клієнтів. Це змінює правила гри!',
    },
    'comment7': {
        'en': 'I\'ve been using Pro Coach for a few months now, and it\'s been an incredible asset. The combination of video instructions and the option to tailor each set\'s timing has really helped me push my clients to new limits. It\'s a must-have for any serious coach!',
        'ua': 'Я використовую Pro Coach уже кілька місяців, і це було неймовірною перевагою. Поєднання відеоінструкцій і можливості адаптувати час для кожного сету дійсно допомогло мені підштовхнути моїх клієнтів до нових меж. Це обов\'язковий атрибут будь-якого серйозного тренера!',
    },
    'comment8': {
        'en': 'As a coach, Pro Coach has become an essential part of my toolkit. The convenience of setting up timing for each set and providing detailed equipment information has elevated my coaching sessions. My clients are more motivated and focused than ever before!',
        'ua': 'Як тренера, Pro Coach став важливою частиною мого набору інструментів. Зручність налаштування часу для кожного підходу та надання детальної інформації про обладнання підняли мої тренінгові сесії. Мої клієнти стали більш мотивованими та цілеспрямованими, ніж будь-коли раніше!',
    },
    'comment9': {
        'en': 'The QR code scanning feature on Pro Coach is so convenient! I scanned, paid, and started my training program within minutes. It\'s seamless and hassle-free.',
        'ua': 'Функція сканування QR-коду на Pro Coach така зручна! Я просканував, оплатив і розпочав свою навчальну програму за лічені хвилини. Це бездоганно та без проблем.',
    },
    'comment10': {
        'en': 'I love how easy it is to get started with Pro Coach. Scan, pay, and boom, you\'re on your way to a healthier you. The app makes fitness accessible to everyone.',
        'ua': 'Мені подобається, як легко почати роботу з Pro Coach. Скануйте, платіть і бум, ви на шляху до здоров’я. Додаток робить фітнес доступним для кожного.',
    },
    'comment11': {
        'en': 'Pro Coach has nailed the user experience. Scanning a QR code to start my training program is genius. It\'s like having a personal trainer in my pocket!',
        'ua': 'Pro Coach покращив користувацький досвід. Сканувати QR-код, щоб розпочати мою навчальну програму, — це геніально. Це як особистий тренер у моїй кишені!',
    },
    'comment12': {
        'en': 'I\'m not tech-savvy, but even I found the QR code process on Pro Coach super easy. It\'s great that they\'ve made it so simple to get started on your sports journey.',
        'ua': 'Я не розбираюся в техніці, але навіть я вважаю процес QR-коду на Pro Coach надзвичайно простим. Чудово, що вони зробили так просто розпочати свою спортивну подорож.',
    },
};
