import './UserCard.css';
import {  memo, useState } from 'react';
import { useSelector } from 'react-redux';
import threedotsHover from '../../assets/threedotsHover.svg';
import ModalWindow from '../ModalWindow/ModalWindow';
import { translations } from '../../localization';
import defaultAvatar from '../../assets/defaultAvatar.png';
import helpIcon from '../../assets/helpIcon.svg';
import { fetchRequest, handleDeleteAssigned } from '../../helpers/Utils';
import { setUsers } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import { setShowMessageObj } from '../../store/homeSlice';
import { SUCCESS } from '../../helpers/Config';

function UserCard({user, index, isActive, handleClick, setTotalCount=null}) {
    const windowInnerWidth = useSelector(state => state.userSlice.windowInnerWidth);
    const language = useSelector(state => state.userSlice.language);
    const users = useSelector(state => state.userSlice.users);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [isModalCancel, setIsModalCancel] = useState(false);
    const [lastTrainings, setLastTrainings] = useState(user.archive_trainings_name?.length ? [...user.archive_trainings_name] : []);
    const dispatch = useDispatch()
    let token = localStorage.getItem('token-admin')

    const handleModalCancel = () => {
        setIsModalCancel(true)
        setIsOpenInfo(false)
    }

    const handleOpenAddTraining = () => {
        setIsOpenInfo(false)
        handleClick(user)
    }

    const handleIsDelete = (res) => {
        if (res.success) {
            dispatch(setUsers([...users.filter(element => element.assigned_id !== user.assigned_id)]))
            dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['dataDeleted'][language]}));
            if (setTotalCount) {
                setTotalCount(prev => prev - 1)
            }
        } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
        }
        setIsModalCancel(false)
    }

    const handleIsModalCancel = (boolean) => {
        if (boolean) {
            let data = {
            }
            handleDeleteAssigned(token, data, user.assigned_id, handleIsDelete)
        } else {
            setIsModalCancel(false)
        }
    }

    return (
        <>
            {
                isOpenInfo && 
                    <ModalWindow 
                        title={''} 
                        handleClick={() => setIsOpenInfo(false)} 
                        notBtn={true}
                        addStyles={{maxWidth: windowInnerWidth < 960 ? '500px' : '580px', maxHeight: '95vh'}}
                    >
                        <div className='user-card__modal-img-wrap'>
                            <div className='user-card__img-wrap'>
                                <img 
                                    className='user-card__modal-img' 
                                    src={user.image?.length ? user.image : defaultAvatar} 
                                    alt='img'
                                />
                                {
                                    (!user.image?.length && !!user.first_name?.length && !!user.last_name?.length) && 
                                        <div className='user-card__modal-img-text'>{user.first_name[0]}{user.last_name[0]}</div>
                                }
                            </div>
                            <div className='user-card__modal-text-wrap'>
                                <div className='user-card__modal-text-name'>{user.first_name + ' ' + user.last_name}</div>
                                <div className='user-card__modal-text'>{user.email}</div>
                                <div className='user-card__modal-text user-card__modal-text--phone'>{user.phone}</div>
                                {
                                    !isActive &&
                                        <>
                                            <div className='user-card__modal-info-wrap'>
                                                <div className='user-card__modal-info'>
                                                    <div className='user-card__modal-info-title'>{translations['trainingPrice'][language]}:</div>
                                                    <div className='user-card__modal-info-text'>{user.paid_status === SUCCESS ? '$' + user?.price : translations['notPaid'][language]}</div>
                                                </div>
                                                <div className='user-card__modal-info'>
                                                    <div className='user-card__modal-info-title'>{translations['daysToFinish'][language]}:</div>
                                                    <div className='user-card__modal-info-text'>{user.days_left} {translations['days'][language]}</div>
                                                </div>
                                                <div className='user-card__modal-info'>
                                                    <div className='user-card__modal-info-title'>{translations['expireIn'][language]}:</div>
                                                    <div className='user-card__modal-info-text'>{user.days_expire} {translations['days'][language]}</div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                        {
                            !!lastTrainings?.length &&
                                <>
                                    <div className='user-card__modal-archive-text'>{translations['lastTrainings'][language]}</div>
                                    <div className='user-card__modal-archive-trainings-wrap'>
                                        {
                                            lastTrainings.map((el, i) => (
                                                <div className='user-card__modal-archive-trainings' key={el + i}>
                                                    <div className='user-card__modal-archive-trainings-num'>{i + 1}</div>
                                                    <div className='user-card__modal-archive-trainings-text'>{el}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                        }
                        {
                            !isActive ?
                                <div className='user-card__modal-btn-wrap'>
                                    <button 
                                        className='mainBtnWhite' 
                                        onClick={() => setIsOpenInfo(false)}
                                    >
                                        {translations['back'][language]}
                                    </button>
                                    {
                                        user.paid_status === SUCCESS ?
                                            <button 
                                                className='mainBtnDark' 
                                                onClick={handleOpenAddTraining}
                                            >
                                                {translations['changeExpireDate'][language]}
                                            </button>
                                            :
                                            <button 
                                                className='mainBtnDark' 
                                                onClick={() => handleModalCancel(true)}
                                            >
                                                {translations['cancelTraining'][language]}
                                            </button>
                                    }
                                </div>
                                :
                                <div className='user-card__modal-btn-wrap'>
                                    <button 
                                        className='mainBtnWhite' 
                                        onClick={() => setIsOpenInfo(false)}
                                    >
                                        {translations['back'][language]}
                                    </button>
                                    <button 
                                        className='mainBtnDark' 
                                        onClick={handleOpenAddTraining}
                                    >
                                        {translations['offerTraining'][language]}
                                    </button>
                                </div>
                        }
                    </ModalWindow>
            }
            {
                isModalCancel && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleIsModalCancel}
                    >
                        <div className='admin-trainings__dialogform-delete-wrap'>
                            <img 
                                className='admin-trainings__dialogform-delete-img' 
                                src={helpIcon} 
                                alt='img'
                            />
                            <div className='admin-trainings__dialogform-delete-text'>
                                <div>{translations['sureFinishedTraining'][language]}</div>
                                <div className='admin-trainings__dialogform-delete-text-name'>{user.training_name}</div>
                            </div>
                        </div>
                    </ModalWindow>
            }
            {
                windowInnerWidth < 640 ? 
                    <>
                        {
                            isActive ?
                                <div className='user-card__mobile user-card__mobile--main'>
                                    <div className='user-card__mobile-text user-card__mobile-text--main'>
                                        <div className='user-card__mobile-text-img-wrap'>
                                            <div className='user-card__img-wrap'>
                                                <img 
                                                    className='user-card__mobile-img' 
                                                    src={user.image?.length ? user.image : defaultAvatar} 
                                                    alt='img'
                                                />
                                                {
                                                    (!user.image?.length && !!user.first_name?.length && !!user.last_name?.length) && 
                                                        <div className='user-card__img-text'>{user.first_name[0]}{user.last_name[0]}</div>
                                                }
                                            </div>
                                            <div>
                                                <div className='user-card__mobile-text-name'>{user.first_name + ' ' + user.last_name}</div>
                                                <div className='user-card__mobile-text-phone'>{user.training_name}</div>
                                            </div>
                                        </div>
                                        <button 
                                            className='user-card__btn-mobile-more' 
                                            onClick={() => setIsOpenInfo(true)}
                                        >
                                            <img 
                                                className='user-card__btn-more-img' 
                                                src={threedotsHover} 
                                                alt='img'
                                            />
                                        </button>
                                    </div>
                                    <div className='user-card__mobile-text-days-wrap'>
                                        <div className='user-card__mobile-text-email-wrap'>
                                             <div className='user-card__mobile-text-email'>{user.email}</div>
                                             <div className='user-card__mobile-text-phone'>{user.phone}</div>
                                         </div>
                                        <div className='user-card__mobile-img-wrap'>
                                            <button 
                                                className='user-card__mobile-btn' 
                                                onClick={() => handleClick(user)}>{translations['offer'][language]}</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='user-card__mobile user-card__mobile--main'>
                                    <div className='user-card__mobile-text user-card__mobile-text--main'>
                                        <div className='user-card__mobile-text-img-wrap'>
                                            <div className='user-card__img-wrap'>
                                                <img 
                                                    className='user-card__mobile-img' 
                                                    src={user.image?.length ? user.image : defaultAvatar} 
                                                    alt='img'
                                                />
                                                {
                                                    (!user.image?.length && !!user.first_name?.length && !!user.last_name?.length) && 
                                                        <div className='user-card__img-text'>{user.first_name[0]}{user.last_name[0]}</div>
                                                }
                                            </div>
                                            <div>
                                                <div className={`user-card__mobile-text-name ${user.paid_status !== SUCCESS && 'opacityContainer-5'}`}>{user.first_name + ' ' + user.last_name}</div>
                                                <div className={`user-card__mobile-text-phone ${user.paid_status !== SUCCESS && 'opacityContainer-5'}`}>{user.training_name}</div>
                                            </div>
                                        </div>
                                        <button 
                                            className='user-card__btn-mobile-more' 
                                            onClick={() => setIsOpenInfo(true)}
                                        >
                                            <img 
                                                className='user-card__btn-more-img' 
                                                src={threedotsHover} 
                                                alt='img'
                                            />
                                        </button>
                                    </div>
                                    <div className={`user-card__mobile-text-days-wrap ${user.paid_status !== SUCCESS && 'opacityContainer-5'}`}>
                                        <div className='user-card__mobile-text-days'>
                                            <div className='user-card__mobile-text-days-text'>{translations['daysToFinish'][language]}:</div>
                                            <div className='user-card__mobile-text-days-text'>{user.days_left} {translations['days'][language]}</div>
                                        </div>
                                        <div className='user-card__mobile-text-days'>
                                            <div className='user-card__mobile-text-days-text'>{translations['expireIn'][language]}:</div>
                                            <div className='user-card__mobile-text-days-text'>{user.days_expire} {translations['days'][language]}</div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
                :
                <div className={`user-card ${isActive ? 'user-card--archive' : ''}`}>
                    <div className='user-card__name-wrap'>
                        <div className='user-card__num'>{index + 1}</div>
                        <div className='user-card__img-wrap'>
                            <img 
                                className='user-card__img' 
                                src={user.image?.length ? user.image : defaultAvatar} 
                                alt='img'
                            />
                            {
                                (!user.image?.length && !!user.first_name?.length && !!user.last_name?.length) && 
                                    <div className='user-card__img-text'>{user.first_name[0]}{user.last_name[0]}</div>
                            }
                        </div>
                        <div className={`user-card__name ${((user.paid_status !== SUCCESS) && !isActive) && 'opacityContainer-5'}`}>{user.first_name + ' ' + user.last_name}</div>
                    </div>
                    <div className={`user-card__training ${((user.paid_status !== SUCCESS) && !isActive) && 'opacityContainer-5'}`}>{user.training_name?.length ? user.training_name : 'Back training'}</div>
                    {
                        !isActive ?
                            <>
                                <div className={`user-card__days ${((user.paid_status !== SUCCESS) && !isActive) && 'opacityContainer-5'}`}>{user.days_left} {translations['days'][language]}</div>
                                <div className={`user-card__expire ${((user.paid_status !== SUCCESS) && !isActive) && 'opacityContainer-5'}`}>{user.days_expire} {translations['days'][language]}</div>
                                <div className={`user-card__expire ${((user.paid_status !== SUCCESS) && !isActive) && 'opacityContainer-5'}`}>{(user.paid_status === SUCCESS) ? '$' + user?.price : translations['notPaid'][language]}</div>
                            </>
                            :
                            <div className='user-card__btn-wrap'>
                                <button 
                                    className='mainBtnWhite user-card__btn' 
                                    onClick={() => handleClick(user)}
                                >
                                    {translations['offerTraining'][language]}
                                </button>
                            </div>
                    }
                    <button 
                        className='user-card__btn-more' 
                        onClick={() => setIsOpenInfo(true)}
                    >
                        <img 
                            className='user-card__btn-more-img' 
                            src={threedotsHover} 
                            alt='img'
                        />
                    </button>
                </div>
            }
        </>
    );
}

export default memo(UserCard);