import './SelectLanguage.css';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleGeoLocation } from '../../helpers/Utils';

function SelectLanguage({isLayoutHome=false, isSignIn=false, isHeader=false, setIsPreloader=null}) {
    const language = useSelector(state => (isHeader || isSignIn || isLayoutHome) ? state.homeSlice.language : state.userSlice.language);
    const dispatch = useDispatch();
    const [setLanguageAction, setSetLanguageAction] = useState(null);

    useEffect(() => {
        if (isHeader || isSignIn || isLayoutHome) {
            import('../../store/homeSlice').then(module => {
                setSetLanguageAction(() => module.setLanguage);
            });
        } else {
            import('../../store/userSlice').then(module => {
                setSetLanguageAction(() => module.setLanguage);
            });
        }
    }, []);
    
    useEffect(() => {
        if (setLanguageAction) {
            let localLanguage = localStorage.getItem("localLanguage")
            if (!localLanguage?.length) {
                handleGetLanguage();
            } else {
                dispatch(setLanguageAction(localLanguage));
            }
        }
    }, [setLanguageAction]);

    const handleSelectLanguage = (str) => {
        if (setLanguageAction) {
            dispatch(setLanguageAction(str));
            localStorage.setItem("localLanguage", str);
        }
    }

    const handleGetLanguage = () => {
        const language = handleGeoLocation();
        dispatch(setLanguageAction(language));
        localStorage.setItem("localLanguage", language)
        if (setIsPreloader) {
            setIsPreloader(false);
        }
    };

    return (
		<Select
            labelId="select-exercise__select-lenguage"
            value={language}
            label={language}
            onChange={(e) => handleSelectLanguage((e.target.value))}
            className={`select-language__select-lenguage ${isLayoutHome ? 'layout-home__select-lenguage' : ''} ${isSignIn ? 'sign-in__desktop-select-lenguage' : ''}`}
            MenuProps={{
                classes: {
                    root: 'select-language__select-lenguage-items',
                },
            }}
        >
            <MenuItem className='select-language__select-lenguage-item' value={'en'}>EN</MenuItem>
            <MenuItem className='select-language__select-lenguage-item' value={'ua'}>UA</MenuItem>
        </Select>
    );
}

export default memo(SelectLanguage);