import './UserView.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CoachingCard from '../../components/CoachingCard/CoachingCard';
import { useEffect, useState } from 'react';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { handleGetAllTrainings } from '../../helpers/Utils';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import { setUserTrainings } from '../../store/homeSlice';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import manSport from '../../assets/manSport.svg';
import ellipse from '../../assets/ellipse.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import finish from '../../assets/finish.svg';
import ConfettiFinish from '../../components/ConfettiFinish/ConfettiFinish';

function UserView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const tokenSearchParams = newSearchParams.get('token')
    const assigned_idSearchParams = newSearchParams.get('assigned_id')
    const is_paidSearchParams = newSearchParams.get('is_paid')
    const language = useSelector(state => state.homeSlice.language);
    const userTrainings = useSelector(state => state.homeSlice.userTrainings);
    const user = useSelector(state => state.homeSlice.user);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isModalFinished, setIsModalFinished] = useState(false);
    const { userId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = localStorage.getItem('token-user')

    useEffect(() => {
        if (user?._id?.length) {
            handleGetTrainings()
        }

        if (is_paidSearchParams?.length && tokenSearchParams?.length && assigned_idSearchParams?.length) {
            setIsModalFinished(true);
        }
    }, [user])

    const handleSetTrainings = (res) => {
        if (res?.success && res?.data) {
            dispatch(setUserTrainings(res.data?.items?.length ? res.data?.items : []))
        } 
        if (!res?.success) {
            navigate(`/user/${userId}/not-found`)
        }
        setIsPreloader(false);
    }

    const handleGetTrainings = () => {
        token = token?.length ? token : tokenSearchParams
        setIsPreloader(true);
        handleGetAllTrainings(token , null, null, '', '', true, handleSetTrainings);
    }

    const handleCloseModalFinished = () => {
        setIsModalFinished(false)
        const newSearchParams = new URLSearchParams();
        setSearchParams(newSearchParams);
    }

    return (
        <div className="user-view-wrap">
            <PageMeta {...pageMeta['UserView']} />

            {
                isModalFinished && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleCloseModalFinished} 
                        notBtn={true}
                    >
                        <img 
                            className='time-progress__modal-img' 
                            src={finish} 
                            alt='img'
                        />
                        <h3 className='time-progress__modal-title'>{translations['congratulation'][language]}</h3>
                        <div className='time-progress__modal-text'>
                            <span>{translations['paymentSuccessful'][language]}</span>
                        </div>
                        <div className='time-progress__modal-btn-wrap'>
                            <button 
                                className='mainBtnDark mainBtn--whidth' 
                                onClick={handleCloseModalFinished}
                            >
                                {translations['close'][language]}
                            </button>
                        </div>
                        <ConfettiFinish />
                    </ModalWindow>
            }

            <div className="user-view container">
                {
                    isPreloader && 
                        <PreloaderCustom 
                            newStyle={{height: `100vh`, left: `0`, top: '0'}} 
                            isNewStyleLoader={true}
                        />
                }
                {
                    !!userTrainings?.length ?
                        <div className="user-view__trending-wrap">
                            {
                                userTrainings.map((el, index) => (
                                    <CoachingCard 
                                        coaching={el} 
                                        setIsPreloader={setIsPreloader}
                                        index={index} 
                                        key={`${el._id}-${index}`}
                                    />
                                ))
                            }
                        </div>
                        :
                        <div className='user-view__not-cards'>
                            <img 
                                className='user-view__not-cards-img-man' 
                                src={manSport} 
                                alt='img'
                            />
                            <img 
                                className='user-view__not-cards-img-circle' 
                                src={ellipse} 
                                alt='img'
                            />
                            <h3 className='user-view__not-cards-title'>{translations['askQrCode'][language]}</h3>
                        </div>
                }
            </div>
        </div>
    );
}

export default UserView;