import './SignUpView.css';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchGetData, fetchRequest, handleDeleteToken, handleFocusAppInput, handleStartLambda, validEmail, validatePassword, validatePhone } from '../../helpers/Utils';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import PhoneInput from 'react-phone-input-2';
import arrowLeft from '../../assets/arrowLeft.svg';
import singUpBtn from '../../assets/singUpBtn.svg';
import TextInput from '../../components/TextInput/TextInput';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import logoWhite from '../../assets/logoWhite.svg';
import sportMan4 from '../../assets/sportMan4.png';
import DoubleButtons from '../../components/DoubleButtons/DoubleButtons';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { setShowMessageObj, setUser } from '../../store/homeSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setCoach, setIsInputFocus } from '../../store/userSlice';
import qrAppStore from '../../assets/qrAppStore.svg';
import qrCodeGoogle from '../../assets/qrCodeGoogle.svg';
import btnGooglePlay from '../../assets/btnGooglePlay.svg';
import btnAppStore from '../../assets/btnAppStore.svg';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { COMPANY, INDIVIDUAL, stripeCountries } from '../../helpers/Config';

function SignUpView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const is_coachSearchParams = searchParams.get('is_coach')
    const is_appSearchParams = newSearchParams.get('is_app')
    const language = useSelector(state => state.homeSlice.language);
    const is_app = useSelector(state => state.homeSlice.is_app);
    const isInputFocus = useSelector(state => state.userSlice.isInputFocus);
    const [isApp, setIsApp] = useState((is_appSearchParams && is_appSearchParams !== 'undefined') ? true : false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [country, setCountry] = useState('');
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorBusiness_country, setIsErrorBusiness_country] = useState(false);
    const [isErrorBusiness_name, setIsErrorBusiness_name] = useState(false);
    const [isCoach, setIsCoach] = useState(is_coachSearchParams?.length ? JSON.parse(is_coachSearchParams) : true);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isErrorPhone, setIsErrorPhone] = useState(false);
    const [isOpenBusinessType, setIsOpenBusinessType] = useState(false);
    const [isOpenBusinessCountry, setIsOpenBusinessCountry] = useState(false);
    const [business_type, setBusiness_type] = useState(INDIVIDUAL);
    const [business_country, setBusiness_country] = useState('');
    const [business_name, setBusiness_name] = useState('');
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth)
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let userToken = localStorage.getItem('token-user')
    let adminToken = localStorage.getItem('token-admin')
    let is_appLocal = localStorage.getItem('is_app')

    useEffect(() => {
        handleStartLambda(dispatch)
        if (isApp && userToken?.length && !adminToken?.length) {
            verifyToken(userToken)
        } else if (isApp && !userToken?.length && adminToken?.length) {
            verifyToken(adminToken)
        } else {
            setIsPreloader(false);
        }

        if (isApp || is_appLocal) {
            localStorage.setItem('is_app', JSON.stringify(true))
        }
        
        window.addEventListener("resize", handleResize);
           
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setIsErrorEmail(email?.length && validEmail(email) ? false : false)
        setIsErrorPassword(password?.length && validatePassword(password) ? false : false)
        setIsErrorName((firstName?.length && lastName?.length) ? false : false)
        setIsErrorBusiness_country(business_country?.length ? false : false)
        setIsErrorBusiness_name((business_type === INDIVIDUAL || business_name?.length) ? false : false)
    }, [password, email, confirmPassword, firstName, lastName, business_country, business_name]);

    useEffect(() => {
        setIsErrorPhone(false)
    }, [phone])
   
    const handleResize = () => {
        setWindowInnerWidth(window.innerWidth)   
    }

    const handleChange = () => {
        let test1 = email?.length && validEmail(email) ? true : false
        let test2 = (password?.length && password === confirmPassword && validatePassword(password)) ? true : false
        let test3 = (firstName?.length && lastName?.length) ? true : false
        let test4 = ((phone?.length && validatePhone(phone, country)) || !phone?.length)
        let test5 = business_country?.length ? true : false
        let test6 = (business_type === INDIVIDUAL || business_name?.length) ? true : false
        if (test1 && test2 && test3 && test4 && test5 && test6) {
            setIsPreloader(true)
            let data = {
                first_name: firstName,
                last_name: lastName,
                email: email.trim(),
                password: password,
                phone: phone,
                location: userLocation,
                is_coach: isCoach,
                is_google_auth: false,
                business_type: business_type,
                business_name: business_type === INDIVIDUAL ? '' : business_name,
                country: business_country,
                is_mobile_device: (isMobileDevice && windowInnerWidth < 640) ? true : false
            };
    
            fetchRequest('POST', `/auth/register`, data)
                .then(res => {
                    if (res?.success && res.data?.user) {
                        handleUpdateData(res.data)
                    } else {
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorRegisterEmail'][language] : translations['anErrorOccurred'][language] }))
                    }
                    setIsPreloader(false)
                })
        } else {
            setIsErrorPhone((phone?.length && validatePhone(phone, country)) ? false : true)
            setIsErrorEmail(!test1 && true)
            setIsErrorPassword(!test2 && true)
            setIsErrorName(!test3 && true)
            setIsErrorBusiness_country(!test5 && true)
            setIsErrorBusiness_name(!test6 && true)
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['mandatoryFieldsRegister'][language]}))
        }
    }

    const handleGoogleRegister = async (googleData) => {
        setIsPreloader(true)
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
            method: "POST",
            body: JSON.stringify({...googleData, is_coach: isCoach, is_mobile_device: (isMobileDevice && windowInnerWidth < 640) ? true : false}),
            headers: {
                "Content-Type": "application/json"
            }
        })
        const resData = await res.json()
        if (!resData.success) {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: resData?.statusCode === 401 ? translations['errorRegisterEmail'][language] : translations['anErrorOccurred'][language] }))
        } else {
            handleUpdateData(resData.data)
        }
        setIsPreloader(false)
    }

    const handleUpdateData = (obj) => {
        const { user, token } = obj;
        const userType = user?.is_coach ? 'coach' : 'user';
        const tokenKey = userType === 'coach' ? 'token-admin' : 'token-user';
        const navigatePath = userType === 'coach' 
            ? `/auth/${user._id}/trainings` 
            : `/user/${user._id}`;

        navigate(isApp ? `${navigatePath}?is_app=true` : navigatePath);
        dispatch(userType === 'coach' ? setCoach(user) : setUser(user));
        localStorage.setItem(tokenKey, token);
        handleDeleteToken(isApp, user);
    }

    const handleSelectTab = (boolean) => {
        setIsCoach(boolean);
        newSearchParams.set('is_coach', JSON.stringify(boolean));
        setSearchParams(newSearchParams);
    }

    const handlePhoneChange = (value, country) => {
        setPhone(value);
        setCountry(country.countryCode);
    };

    const verifyToken = async (token) => {
        setIsPreloader(true)
        if (token?.length) {
            fetchGetData(`/auth/verify?token=${token}`)
                .then(res => {
                    if (res?.success && res.data?._id?.length) {
                        if (res.data?.is_coach) {
                            navigate(`/auth/${res.data._id}/trainings?is_app=true`)
                            localStorage.setItem('is_app', JSON.stringify(true))
                            localStorage.removeItem('token-user')
                            dispatch(setCoach(res.data))
                        } else {
                            navigate(`/user/${res.data?._id}?is_app=true`)
                            localStorage.setItem('is_app', JSON.stringify(true))
                            localStorage.removeItem('token-admin')
                            dispatch(setUser(res.data))
                        }
                    } 
                    setIsPreloader(false)
                })
        } 
    }

    const handleClick = () => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('googleSignUp', 'register');
        } 
    };

    const handleSelectBusinessType = (e) => {
        setBusiness_type(e.target.value);
    }

    const handleSelectBusinessCountry = (e) => {
        setBusiness_country(e.target.value);
    }
    
    return (
        <div className='sign-up-wrap'>
            <PageMeta {...pageMeta['SignUpView']} />
            {
                windowInnerWidth < 960 ?
                    <div className='sign-up container'>
                        <div className='sign-up__form-wrap'>
                            {
                                isPreloader && 
                                    <PreloaderCustom
                                        newStyle={{ height: '100vh'}}
                                        isNewStyleLoader={true}
                                    />
                            }
                            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                                <div className='sign-in__form-title-wrap'>
                                    {
                                        isApp ?
                                            <div className='sign-up__form-btn-back sign-in__form-btn-back--app'>
                                                <div className='sign-up__form-title sign-in__form-title--app'>{translations['registration'][language]}</div>
                                            </div>
                                            :
                                            <button 
                                                className='sign-up__form-btn-back' 
                                                onClick={() => navigate(-1)}
                                            >
                                                <img 
                                                    className='sign-up__form-btn-back-img' 
                                                    src={arrowLeft} 
                                                    alt='img'
                                                />
                                                <div className='sign-up__form-title'>{translations['registration'][language]}</div>
                                            </button>
                                    }
                                    <SelectLanguage isSignIn={true} />
                                </div>
                                <div className='sign-up__form'>
                                    <div className='sign-up__form-input-wrap'>
                                        <div className='sign-up__input-form--wrap'>
                                            <div className='sign-up__form-user-btn-wrap sign-up__form-user-btn-wrap--mob'>
                                                <DoubleButtons 
                                                    isActive={isCoach}
                                                    leftBtnText={translations['coach'][language]} 
                                                    rightBtnText={translations['bodybuilder'][language]} 
                                                    onClickLeftBtn={() => handleSelectTab(true)} 
                                                    onClickRightBtn={() => handleSelectTab(false)}
                                                />
                                            </div>
                                            {
                                                (isCoach || isApp) ?
                                                    <div className={`sign-up__form-input-container ${isInputFocus ? 'appInputFocus' : ''}`}>
                                                        <TextInput 
                                                            setValue={setEmail} 
                                                            value={email} 
                                                            label="Email" 
                                                            helperText={isErrorEmail ? (email.length && validEmail(email)) ? translations['errorEmail'][language] : translations['errorEmail3'][language] : ''}
                                                        />
                                                        <PasswordInput 
                                                            password={password} 
                                                            label={translations['password'][language]}
                                                            setPassword={setPassword} 
                                                            errorPassword={isErrorPassword ? !password?.length ? translations['enterPassword'][language] : translations['errorPassword'][language] : ''}
                                                        />
                                                        <PasswordInput 
                                                            password={confirmPassword} 
                                                            setPassword={setConfirmPassword} 
                                                            label={translations['confirmPassword'][language]} 
                                                            errorPassword={(isErrorPassword && confirmPassword !== password) ? translations['passwordsNotMatch'][language] : ''}
                                                        />
                                                        <TextInput 
                                                            setValue={setFirstName} 
                                                            value={firstName} 
                                                            label={translations['firstName'][language]} 
                                                            helperText={(isErrorName && !firstName?.length) ? translations['enterFirstName'][language] : ''}
                                                        />
                                                        <TextInput 
                                                            setValue={setLastName} 
                                                            value={lastName} 
                                                            label={translations['lastName'][language]} 
                                                            helperText={(isErrorName && !lastName?.length) ? translations['enterLastName'][language] : ''}
                                                        />
                                                        <div className='sign-up__form-phone-wrap'>
                                                            <PhoneInput
                                                                className={`sign-up__form-phone ${phone?.length > 4 ? 'sign-up__form-phone--is-active' : ''}`}
                                                                country={'ua'}
                                                                value={!phone?.length ? '380' : phone}
                                                                onChange={handlePhoneChange}
                                                                onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_appLocal?.length ? true : false)}
                                                                onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_appLocal?.length ? true : false)}
                                                            />
                                                            {
                                                                isErrorPhone && <div className='sign-up__form-phone-error'>{translations['errorPhone'][language]}</div>
                                                            }
                                                        </div>
                                                        <TextInput 
                                                            setValue={setUserLocation} 
                                                            value={userLocation} 
                                                            label={`${translations['location'][language]} (${translations['optional'][language]})`}
                                                        />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="business-type__label">{translations['businessType'][language]}</InputLabel>
                                                            <Select
                                                                open={isOpenBusinessType}
                                                                onOpen={() => setIsOpenBusinessType(true)}
                                                                onClose={() => setIsOpenBusinessType(false)}
                                                                labelId="business-type__label"
                                                                value={business_type}
                                                                label={translations['businessType'][language]}
                                                                onChange={handleSelectBusinessType}
                                                                className={`sign-up__form-business-type-select ${(isOpenBusinessType) ? 'mu-component-is-active' : ''}`}
                                                                MenuProps={{
                                                                    classes: {
                                                                        root: 'select-exercise__select',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={INDIVIDUAL} className='sign-up__form-business-type-item'>{translations['individualBusiness'][language]}</MenuItem>
                                                                <MenuItem value={COMPANY} className='sign-up__form-business-type-item'>{translations['corporationBusiness'][language]}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            business_type === COMPANY &&
                                                                <TextInput 
                                                                    setValue={setBusiness_name} 
                                                                    value={business_name} 
                                                                    label={translations['businessName'][language]}
                                                                    helperText={(isErrorBusiness_name && business_type === COMPANY) ? translations['fieldRequired'][language] : ''}
                                                                />
                                                        }
                                                        <FormControl fullWidth>
                                                            <InputLabel id="business-country__label">{translations['businessCountry'][language]}</InputLabel>
                                                            <Select
                                                                open={isOpenBusinessCountry}
                                                                onOpen={() => setIsOpenBusinessCountry(true)}
                                                                onClose={() => setIsOpenBusinessCountry(false)}
                                                                labelId="business-country__label"
                                                                value={business_country}
                                                                renderValue={business_country ? (value) => stripeCountries.find(el => el.value === value)?.name : ''}
                                                                label={translations['businessCountry'][language]}
                                                                onChange={handleSelectBusinessCountry}
                                                                className={`sign-up__form-business-type-select ${(isOpenBusinessCountry) ? 'mu-component-is-active' : ''}`}
                                                                MenuProps={{
                                                                    classes: {
                                                                        root: 'select-exercise__select',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={''} disabled >{translations['businessCountry'][language]}</MenuItem>
                                                                {
                                                                    !!stripeCountries.length && stripeCountries.map((el, i) => (
                                                                        <MenuItem key={i} value={el.value} className='sign-up__form-business-type-item'>{el.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                            {
                                                                isErrorBusiness_country && <div className='sign-up__form-phone-error'>{translations['fieldRequired'][language]}</div>
                                                            }
                                                        </FormControl>
                                                        <div className='sign-up__form-input-btn-wrap'>
                                                            <div className='sign-up__form-input-btn--wrap'>
                                                                <button 
                                                                    className='mainBtnDark mainBtn--whidth sign-up__form-btn-sign-up' 
                                                                    onClick={handleChange}
                                                                >
                                                                    <span>{translations['signUp'][language]}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='sign-up__user-login-wrap'>   
                                                        <div className='sign-up__user-login-qr-wrap'>
                                                            <img 
                                                                className='sign-up__user-login-qr-img'
                                                                src={qrCodeGoogle}
                                                                alt='img'
                                                            />
                                                            <a 
                                                                className='sign-up__user-login-btn-google-play-link'
                                                                href='https://play.google.com/store/apps/details?id=com.gen.workoutme&hl=ru'
                                                                target='_blank'
                                                            >
                                                                <img 
                                                                    className='sign-up__user-login-btn-google-play'
                                                                    src={btnGooglePlay}
                                                                    alt='img'
                                                                />
                                                            </a>
                                                            <img 
                                                                className='sign-up__user-login-qr-img'
                                                                src={qrAppStore}
                                                                alt='img'
                                                            />
                                                            <a 
                                                                className='sign-up__user-login-btn-google-play-link'
                                                                href='https://apps.apple.com/ua/app/betterme-health-coaching/id1264546236?l=ru'
                                                                target='_blank'
                                                            >
                                                                <img 
                                                                    className='sign-up__user-login-btn-google-play'
                                                                    src={btnAppStore}
                                                                    alt='img'
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className='sign-up__form-btn-wrap'>
                                            {
                                                !isApp ?
                                                    <button className='sign-up__form-google-btn-wrap'>
                                                        <img 
                                                            className='sign-up__form-google-btn-img' 
                                                            src={singUpBtn} 
                                                            alt='img'
                                                        />
                                                        <div className='sign-up__form-google-btn'>
                                                            <GoogleLogin
                                                                onSuccess={handleGoogleRegister}
                                                                onError={handleGoogleRegister}
                                                                useOneTap
                                                            />
                                                        </div>
                                                    </button>
                                                    :
                                                    <button 
                                                        className='sign-up__form-google-btn-wrap'
                                                        onClick={handleClick}
                                                    >
                                                        <img 
                                                            className='sign-up__form-google-btn-img' 
                                                            src={singUpBtn} 
                                                            alt='img'
                                                        />
                                                    </button>
                                            }
                                            <NavLink 
                                                className='sign-up__form-btn-create' 
                                                to={isApp ? '/login?is_app=true' : '/login'}
                                            >
                                                <span>{translations['login'][language]}</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                    :
                    <div className='sign-up__desktop'>
                        <div className='sign-up__desktop-img-wrap'>
                            <NavLink to='/'>
                                <img 
                                    className='sign-up__desktop-img-logo' 
                                    src={logoWhite} 
                                    alt='img'
                                />
                            </NavLink>
                            <img 
                                className='sign-up__desktop-img' 
                                src={sportMan4} 
                                alt='img'
                            />
                        </div>
                        <div className='sign-up__form-wrap'>
                            {
                                isPreloader && 
                                    <PreloaderCustom 
                                        newStyle={{ height: '100vh', with: '60%', maxWidth: window.innerWidth < 960 ? '100%' : '490px'}}
                                        isNewStyleLoader={true}
                                    />
                            }
                            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                                <div className='sign-up__desktop-form'>
                                    <div className='sign-in__input-form--wrap'>
                                        <SelectLanguage isSignIn={true} />
                                        <div className='sign-up__desktop-form-title'>{translations['signUp'][language]}</div>
                                        <div className='sign-up__desktop-form-sub-title'>{translations['startManaging'][language]}</div>
                                        <div className='sign-up__form-user-btn-wrap'>
                                            <DoubleButtons 
                                                isActive={isCoach}
                                                leftBtnText={translations['coach'][language]} 
                                                rightBtnText={translations['bodybuilder'][language]} 
                                                onClickLeftBtn={() => handleSelectTab(true)} 
                                                onClickRightBtn={() => handleSelectTab(false)}
                                            />
                                        </div>
                                        {
                                            isCoach ? 
                                                <>
                                                    <div className='sign-up__desktop-form-input-wrap'>
                                                        <div className='sign-up__desktop-form-input-name-wrap'>
                                                            <TextInput 
                                                                setValue={setFirstName} 
                                                                value={firstName} 
                                                                label={translations['firstName'][language]} 
                                                                helperText={(isErrorName && !firstName?.length) ? translations['enterFirstName'][language] : ''}
                                                            />
                                                            <TextInput 
                                                                setValue={setLastName} 
                                                                value={lastName} 
                                                                label={translations['lastName'][language]}
                                                                helperText={(isErrorName && !lastName?.length) ? translations['enterLastName'][language] : ''}
                                                            />
                                                        </div>
                                                        <TextInput 
                                                            setValue={setEmail} 
                                                            value={email} 
                                                            label="Email" 
                                                            helperText={isErrorEmail ? (email.length && validEmail(email)) ? translations['errorEmail'][language] : translations['errorEmail3'][language] : ''}
                                                        />
                                                        <PasswordInput 
                                                            password={password} 
                                                            label={translations['password'][language]}
                                                            setPassword={setPassword} 
                                                            errorPassword={isErrorPassword ? !password?.length ? translations['enterPassword'][language] : translations['errorPassword'][language] : ''}
                                                        />
                                                        <PasswordInput 
                                                            password={confirmPassword} 
                                                            setPassword={setConfirmPassword} 
                                                            label={translations['confirmPassword'][language]} 
                                                            errorPassword={(isErrorPassword && confirmPassword !== password) ? translations['passwordsNotMatch'][language] : ''}
                                                        />
                                                        <div className='sign-up__form-phone-wrap'>
                                                            <PhoneInput
                                                                className={`sign-up__form-phone ${phone?.length > 4 ? 'sign-up__form-phone--is-active' : ''}`}
                                                                country={'ua'}
                                                                value={!phone?.length ? '380' : phone}
                                                                onChange={handlePhoneChange}
                                                                onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app)}
                                                                onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
                                                            />
                                                            {
                                                                isErrorPhone && <div className='sign-up__form-phone-error'>{translations['errorPhone'][language]}</div>
                                                            }
                                                        </div>
                                                        <TextInput 
                                                            setValue={setUserLocation} 
                                                            value={userLocation} 
                                                            label={`${translations['location'][language]} (${translations['optional'][language]})`}
                                                        />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="business-type__label">{translations['businessType'][language]}</InputLabel>
                                                            <Select
                                                                open={isOpenBusinessType}
                                                                onOpen={() => setIsOpenBusinessType(true)}
                                                                onClose={() => setIsOpenBusinessType(false)}
                                                                labelId="business-type__label"
                                                                value={business_type}
                                                                label={translations['businessType'][language]}
                                                                onChange={handleSelectBusinessType}
                                                                className={`sign-up__form-business-type-select ${(isOpenBusinessType) ? 'mu-component-is-active' : ''}`}
                                                                MenuProps={{
                                                                    classes: {
                                                                        root: 'select-exercise__select',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={INDIVIDUAL} className='sign-up__form-business-type-item'>{translations['individualBusiness'][language]}</MenuItem>
                                                                <MenuItem value={COMPANY} className='sign-up__form-business-type-item'>{translations['corporationBusiness'][language]}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            business_type === COMPANY &&
                                                                <TextInput 
                                                                    setValue={setBusiness_name} 
                                                                    value={business_name} 
                                                                    label={translations['businessName'][language]}
                                                                    helperText={(isErrorBusiness_name && business_type === COMPANY) ? translations['fieldRequired'][language] : ''}
                                                                />
                                                        }
                                                        <FormControl fullWidth>
                                                            <InputLabel id="business-country__label">{translations['businessCountry'][language]}</InputLabel>
                                                            <Select
                                                                open={isOpenBusinessCountry}
                                                                onOpen={() => setIsOpenBusinessCountry(true)}
                                                                onClose={() => setIsOpenBusinessCountry(false)}
                                                                labelId="business-country__label"
                                                                value={business_country}
                                                                renderValue={business_country ? (value) => stripeCountries.find(el => el.value === value)?.name : ''}
                                                                label={translations['businessCountry'][language]}
                                                                onChange={handleSelectBusinessCountry}
                                                                className={`sign-up__form-business-type-select ${(isOpenBusinessCountry) ? 'mu-component-is-active' : ''}`}
                                                                MenuProps={{
                                                                    classes: {
                                                                        root: 'select-exercise__select',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value={''} disabled >{translations['businessCountry'][language]}</MenuItem>
                                                                {
                                                                    !!stripeCountries.length && stripeCountries.map((el, i) => (
                                                                        <MenuItem key={i} value={el.value} className='sign-up__form-business-type-item'>{el.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                            {
                                                                isErrorBusiness_country && <div className='sign-up__form-phone-error'>{translations['fieldRequired'][language]}</div>
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div className='sign-up__desktop-btn-wrap'>
                                                        <button 
                                                            className='mainBtnDark mainBtn--whidth sign-up__form-btn-sign-up' 
                                                            onClick={handleChange}
                                                        >
                                                            {translations['signUp'][language]}
                                                        </button>
                                                    </div>
                                                    <div className='sign-up__desktop-form-or'>
                                                        <div className='sign-up__desktop-form-or-text'>or</div>
                                                    </div>
                                                    {
                                                        !isApp ?
                                                            <button className='sign-up__form-google-btn-wrap'>
                                                                <img 
                                                                    className='sign-up__form-google-btn-img' 
                                                                    src={singUpBtn} 
                                                                    alt='img'
                                                                />
                                                                <div className='sign-up__form-google-btn'>
                                                                    <GoogleLogin
                                                                        onSuccess={handleGoogleRegister}
                                                                        onError={handleGoogleRegister}
                                                                        useOneTap
                                                                    />
                                                                </div>
                                                            </button>
                                                            :
                                                            <button 
                                                                className='sign-up__form-google-btn-wrap'
                                                                onClick={handleClick}
                                                            >
                                                                <img 
                                                                    className='sign-up__form-google-btn-img' 
                                                                    src={singUpBtn} 
                                                                    alt='img'
                                                                />
                                                            </button>
                                                    }
                                                </>
                                                :
                                                <div className='sign-up__user-login-wrap'>  
                                                    <div className='sign-up__user-login-qr-wrap'>
                                                        <img 
                                                            className='sign-up__user-login-qr-img'
                                                            src={qrCodeGoogle}
                                                            alt='img'
                                                        />
                                                        <a 
                                                            className='sign-up__user-login-btn-google-play-link'
                                                            href='https://play.google.com/store/apps/details?id=com.gen.workoutme&hl=ru'
                                                            target='_blank'
                                                        >
                                                            <img 
                                                                className='sign-up__user-login-btn-google-play'
                                                                src={btnGooglePlay}
                                                                alt='img'
                                                            />
                                                        </a>
                                                        <img 
                                                            className='sign-up__user-login-qr-img'
                                                            src={qrAppStore}
                                                            alt='img'
                                                        />
                                                        <a 
                                                            className='sign-up__user-login-btn-google-play-link'
                                                            href='https://apps.apple.com/ua/app/betterme-health-coaching/id1264546236?l=ru'
                                                            target='_blank'
                                                        >
                                                            <img 
                                                                className='sign-up__user-login-btn-google-play'
                                                                src={btnAppStore}
                                                                alt='img'
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className='sign-up__desktop-form-btn--wrap'>
                                        <span className='sign-up__desktop-form-btn-text'>{translations['doYouHaveAccount'][language]}</span>
                                        <NavLink 
                                            className='sign-up__desktop-form-btn' 
                                            to='/login'
                                        >
                                            {translations['logIn'][language]}
                                        </NavLink>
                                    </div>
                                </div>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
            }
        </div>
    );
}

export default SignUpView;