import './CommentsBloc.css';
import { memo, useEffect, useState } from 'react';
import infoCoach1 from '../../assets/infoCoach1.png';
import infoCoach2 from '../../assets/infoCoach2.png';
import infoCoach3 from '../../assets/infoCoach3.png';
import infoCoach4 from '../../assets/infoCoach4.png';
import infoCoach5 from '../../assets/infoCoach5.png';
import infoCoach6 from '../../assets/infoCoach6.png';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';

function CommentsBloc({isCoachesView=false, isHomeView=false, isProductsView=false}) {
    const language = useSelector(state => state.homeSlice.language);
    const [dataArr, setDataArr] = useState([]);
    const [title, setTitle] = useState('comments');
    const coachArr = [
        {
            image: infoCoach1,
            name: 'John Smith',
            text: translations['comment1'][language],
        },
        {
            image: infoCoach2,
            name: 'John Smith',
            text: translations['comment2'][language],
        },
        {
            image: infoCoach3,
            name: 'John Smith',
            text: translations['comment3'][language],
        },
        {
            image: infoCoach4,
            name: 'John Smith',
            text: translations['comment4'][language],
        },
    ]
    
    const homeArr = [
        {
            image: infoCoach1,
            name: 'Michael Thompson',
            text: translations['comment5'][language],
        },
        {
            image: infoCoach2,
            name: 'Emily Johnson',
            text: translations['comment6'][language],
        },
        {
            image: infoCoach3,
            name: 'David Rodriguez',
            text: translations['comment7'][language],
        },
        {
            image: infoCoach4,
            name: 'Jessica Carter',
            text: translations['comment8'][language],
        },
    ]
    
    const productsArr = [
        {
            image: infoCoach3,
            name: 'Samantha',
            text: translations['comment9'][language],
        },
        {
            image: infoCoach4,
            name: 'Alex',
            text: translations['comment10'][language],
        },
        {
            image: infoCoach5,
            name: 'Ryan',
            text: translations['comment11'][language],
        },
        {
            image: infoCoach6,
            name: 'Natalie',
            text: translations['comment12'][language],
        },
    ]

    useEffect(() => {
        setTitle(translations['comments'][language])
        if (isCoachesView) {
            setDataArr([...coachArr]);
            return
        }
        if (isHomeView) {
            setDataArr([...homeArr]);
            return
        }
        if (isProductsView) {
            setDataArr([...productsArr]);
            return
        }
    }, [])

    return (
		<div className='comments-bloc__coments'>
            <div className='comments-bloc__coments-title'>{title}</div>
            <div className='comments-bloc__coments-card-wrap'>
                {
                    !!dataArr?.length && dataArr.map((el, i) => (
                        <div className='comments-bloc__coments-card' key={i}>
                            <img 
                                className='comments-bloc__coments-card-img' 
                                src={el.image} 
                                alt='img'
                            />
                            <div className='comments-bloc__coments-card-text-wrap'>
                                <div className='comments-bloc__coments-card-title'>{el.name}</div>
                                <div className='comments-bloc__coments-card-text'>{el.text}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default memo(CommentsBloc);