import './UploadImage.css';
import {  memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import videoImage from '../../assets/videoImage.svg';
import videoImage2 from '../../assets/videoImage2.svg';
import { Popover } from '@mui/material';

function UploadImage({handleModalImageUrl, handleUploadImg, title, isVideo=false, isUploadImg=false}) {
    const language = useSelector(state => state.userSlice.language);
    const [anchorImageEl, setAnchorImageEl] = useState(null);

    const handleClosePopover = () => {
        setAnchorImageEl(null);
        handleModalImageUrl();
    }

    return (
        <div className='upload-image'>
            <button 
                className='upload-image__wrap' 
                onClick={(e) => setAnchorImageEl(e.currentTarget)}
                disabled={isUploadImg}
            >
                <img 
                    className={`upload-image__img ${isVideo ? 'upload-image__img--video' : ''}`}    
                    src={isVideo ? videoImage2 : videoImage} 
                    alt='img'
                />
                <div className='upload-image__text'>{title}</div>
                {
                    isVideo ?
                        <div className='upload-image__label'>{translations['downloadVideo'][language]}</div>
                        :
                        <div className='upload-image__label'>{translations['downloadImage'][language]}</div>
                }
            </button>
            <Popover
                id={'simple-popover-notifications'}
                open={Boolean(anchorImageEl)}
                anchorEl={anchorImageEl}
                onClose={() => setAnchorImageEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: -60,
                }}
                classes={{
                    root: 'upload-image__popover-wrap',
                }}
            >
                <div className='upload-image__popover'>
                    <button 
                        className='upload-image__popover-btn' 
                        onClick={handleClosePopover}
                    >
                        {translations['byUrl'][language]}
                    </button>
                    <label 
                        className='upload-image__popover-label' 
                        htmlFor={isVideo ? 'uploadImagecreationt' : 'uploadImagecreation2'} 
                        onClick={() => setAnchorImageEl(null)}
                    >
                        {translations['uploadFromComputer'][language]}
                    </label>
                </div>
            </Popover>
            {
                isVideo ?
                    <input 
                        className='create-exercise__create-img-link-upload-input'
                        onChange={(e) => handleUploadImg(e.target.files)} 
                        type="file" 
                        accept="video/*" 
                        id="uploadImagecreationt" 
                    />
                    :
                    <input 
                        className="create-exercise__create-img-link-upload-input" 
                        onChange={(e) => handleUploadImg(e.target.files)} 
                        type="file" 
                        accept="image/*" 
                        id="uploadImagecreation2" 
                    />
            }
        </div>
    );
}

export default memo(UploadImage);