import './SelectExercise.css';
import { memo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBackToCreateTrening, setTrainingObj } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { LAST } from '../../helpers/Config';
import moveUpImg2 from '../../assets/moveUpImg2.svg';
import { formatTime } from '../../helpers/Utils';

function SelectExercise({ exercise, index, dayName, selectExerciseArr=[]}) {
    const language = useSelector(state => state.userSlice.language);
    const exercises = useSelector(state => state.userSlice.exercises);
    const trainingObj = useSelector(state => state.userSlice.trainingObj);
    const [isOpenTrening, setIsOpenTrening] = useState(false);
    const { coachId } = useParams();
    const { dayId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleCreateExercise = () => {
        navigate(`/auth/${coachId}/plans/create`)
        dispatch(setBackToCreateTrening(location.pathname + `?index=${index}`))
        localStorage.setItem('exercisesCount', exercises?.length.toString())
    }

    const handleSelectExercise = (event) => {
        let res = event.target.value
        if (trainingObj.days?.length) {
            let upDay = trainingObj.days?.find(el => el._id === dayId)
            let newExercise = []
            if (upDay?.exercises?.length) {
                if ((index || index == 0) && index !== LAST) {
                    newExercise = [...upDay.exercises.map((el, i) => i === index ? res : el)]
                } else {
                    newExercise = [...upDay.exercises, res]
                }
            } else {
                newExercise = [res]
            }
            if (upDay) {
                dispatch(setTrainingObj({
                    ...trainingObj,
                    days: [...trainingObj.days.map(el => el._id === dayId ? {...el, exercises: [...newExercise]} : el)]
                }))
            } else {
                dispatch(setTrainingObj({
                    ...trainingObj,
                    days: [...trainingObj.days, {_id: dayId, order: dayId, name: dayName, exercises: [res]}]
                }))
            }
        } else {
            dispatch(setTrainingObj({
                ...trainingObj,
                days: [{_id: dayId, order: dayId, name: dayName, exercises: [res]}]
            }))
        }
    }

    const handleRenderValue = (selected) => {
        if (!selected || selected._id === '') {
            return translations['selectExercise'][language];
        }
        return selected.name?.length ? selected.name : translations['restTime'][language] + ' ' + formatTime(selected.time, language);
    };
    
    return (
        <div className='select-exercise--wrap'>
            {
                (index !== LAST && selectExerciseArr?.length > 1) &&
                    <img 
                        className='select-exercise__move-img'
                        src={moveUpImg2} 
                        alt='img'
                    />
            }
            <FormControl className={`select-exercise-wrap ${(index === LAST || selectExerciseArr?.length === 1) ? 'select-exercise-wrap--full' : ''}`} fullWidth>
                <InputLabel id="select-exercise__label">{translations['exercise'][language]}</InputLabel>
                <Select
                    open={isOpenTrening}
                    onOpen={() => setIsOpenTrening(true)}
                    onClose={() => setIsOpenTrening(false)}
                    labelId="select-exercise__label"
                    value={!!exercises?.length && exercises.find(el => el._id === exercise?._id) || {name: '', _id: ''}}
                    renderValue={handleRenderValue}
                    label={translations['exercise'][language]}
                    onChange={handleSelectExercise}
                    className={`${(exercise?._id?.length || isOpenTrening) ? 'mu-component-is-active' : ''}`}
                    MenuProps={{
                        classes: {
                            root: 'select-exercise__select',
                        },
                    }}
                >
                    <MenuItem value={{name: '', _id: ''}} disabled >{translations['selectExercise'][language]}</MenuItem>
                    {
                        !!exercises.length && exercises.map(el => 
                            <MenuItem key={el._id} value={el}>
                                {!!el.name?.length ?
                                    el.name 
                                    : 
                                    translations['restTime'][language] + ' ' + formatTime(el.time, language)
                                }
                            </MenuItem>)
                    }
                </Select>
            </FormControl>
            {
                index === LAST &&
                    <button
                        onClick={handleCreateExercise}
                        className='select-exercise__exercise-add-btn'
                    >
                        <span className='select-exercise__exercise-add-btn-span'>+</span>
                    </button>
            }
        </div>
    );
}

export default memo(SelectExercise);