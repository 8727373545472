import './Footer.css';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { memo, useMemo } from 'react';
import profileImg from '../../assets/profileImg.svg';
import dumbbell from '../../assets/dumbbell.svg';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';

function Footer() {
    const language = useSelector(state => state.homeSlice.language);
    const location = useLocation();
    const { userId } = useParams();
    const { coachingId } = useParams();

    const handleIsActive = useMemo(() => {
        return (path) => {
            let res = false
            res = location.pathname.split('/').includes(path);
            if (location.pathname === `/user/${userId}` && path !== 'profile') {
                res = true
            }
            return res
        }
    }, [location.pathname]);

    return (
        <div className={`footer-wrap ${location.pathname.includes('notifications') ? 'footer-wrap--notifications' : ''}`}>
            <div className="footer container">
                <div className='footer__btn-wrap'>
                    <NavLink 
                        className={`footer__btn ${handleIsActive(`${coachingId}`) ? 'footer__btn--active' : ''}`} 
                        to={`/user/${userId}`}
                    >
                        <img 
                            className={`footer__btn-img ${handleIsActive(`${coachingId}`) ? 'footer__btn-img--active' : ''}`} 
                            src={dumbbell} 
                            alt='img' 
                        />
                        <div className={`footer__btn-text ${handleIsActive(`${coachingId}`) ? 'footer__btn-text--active' : ''}`}>{translations['trainings'][language]}</div>
                    </NavLink>
                    <NavLink 
                        className={`footer__btn ${handleIsActive('profile') ? 'footer__btn--active' : ''}`} 
                        to={'profile'}
                    >
                        <img 
                            className={`footer__btn-img ${handleIsActive('profile') ? 'footer__btn-img--active' : ''}`} 
                            src={profileImg} 
                            alt='img' 
                        />
                        <div className={`footer__btn-text ${handleIsActive('profile') ? 'footer__btn-text--active' : ''}`}>{translations['profile'][language]}</div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default memo(Footer);