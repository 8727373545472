import './CountdownTimer.css';
import React, { useState, useEffect } from 'react';

function CountdownTimer({ setIsStartTimer }) {
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        const startTime = localStorage.getItem('startTime');
        const savedTimer = localStorage.getItem('savedTimer');

        if (startTime && savedTimer) {
            const elapsed = Math.floor((Date.now() - startTime) / 1000);
            const remainingTime = savedTimer - elapsed;
            setTimer(remainingTime > 0 ? remainingTime : 0);
        }

        let interval = null;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    const newTimer = prevTimer - 1;
                    if (newTimer <= 0) {
                        clearInterval(interval);
                        localStorage.removeItem('startTime');
                        localStorage.removeItem('savedTimer');
                        setIsStartTimer(false);
                    }
                    return newTimer;
                });
            }, 1000);

            if (!startTime) {
                localStorage.setItem('startTime', Date.now());
                localStorage.setItem('savedTimer', timer);
            }
        } else {
            setIsStartTimer(false);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const formatTime = timeInSeconds => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='countdown-timer'>
           - {formatTime(timer)}
        </div>
    );
}

export default CountdownTimer;
