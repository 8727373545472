import './RestorePasswordView.css';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import checkCircle from '../../assets/checkCircle.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import sportMan4 from '../../assets/sportMan4.png';
import logoWhite from '../../assets/logoWhite.svg';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { fetchGetData, fetchRequest, handleGeoLocation, validatePassword } from '../../helpers/Utils';
import { setLanguage, setShowMessageObj } from '../../store/homeSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';

function RestorePasswordView() {
    const language = useSelector(state => state.homeSlice.language);
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const tokenSearchParams = newSearchParams.get('token')
    const [newPassword, setNewPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isModalSuccess, setIsModalSuccess] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (tokenSearchParams?.length) {
            verifyToken()
        } else {
            navigate('/login')
            setIsPreloader(false)
        }
        handleGetLanguage();
    }, [])

    useEffect(() => {
        if (isErrorPassword) {
            setIsErrorPassword(false)
        }
    }, [newPassword, repeatedPassword])

    const handleGetLanguage = () => {
        const language = handleGeoLocation();
        dispatch(setLanguage(language));
    };

    const handleSave = () => {
        let test = (newPassword?.length && repeatedPassword === newPassword && validatePassword(newPassword)) ? true : false
        if (test) {
            setIsPreloader(true)
            let data = {
                password: newPassword, 
            }
            fetchRequest('POST', `/auth/new-password?token=${tokenSearchParams}`, data)
                .then(res => {
                    if (res?.success) {
                            setIsModalSuccess(true)
                        } else {
                            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }))
                        }
                        setIsPreloader(false)
                    })
        } else {
            setIsErrorPassword(true)
        }
    }

    const handleIsSuccess = (boolean) => {
        setIsModalSuccess(false)
        navigate('/login')
    }

    const verifyToken = async () => {
        if (tokenSearchParams?.length) {
            fetchGetData(`/auth/verify?token=${tokenSearchParams}`)
                .then(res => {
                    if (res?.success && res.data?._id?.length) {
                        setIsPreloader(false)
                    } else {
                        window.location.assign('/login')
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}))
                    }
                })
        } else {
            setIsPreloader(false)
            window.location.assign('/login')
        }
    }

    return (
        <div className='restore-password-wrap'>
            <PageMeta {...pageMeta['RestorePasswordView']} />
            {
                isModalSuccess && 
                    <ModalWindow 
                        title={''} 
                        handleClick={handleIsSuccess} 
                        notBtn={true}
                    >
                        <img 
                            className='restore-password__modal-img' 
                            src={checkCircle} 
                            alt='img'
                        />
                        <div className='restore-password__modal-title'>{translations['passwordRestored'][language]}</div>
                        <div className='restore-password__modal-btn-wrap'>
                            <button 
                                className='mainBtnDark restore-password__modal-btn' 
                                onClick={handleIsSuccess}
                            >
                                {translations['close'][language]}
                            </button>
                        </div>
                    </ModalWindow>
            }
            <div className='restore-password__desktop-img-wrap'>
                <NavLink to='/'>
                    <img 
                        className='restore-password__desktop-img-logo' 
                        src={logoWhite} 
                        alt='img'
                    />
                </NavLink>
                <img 
                    className='restore-password__desktop-img' 
                    src={sportMan4} 
                    alt='img'
                />
            </div>
            <div className='restore-password container'>
                <div className='restore-password__form-wrap'>
                    {
                        isPreloader && <PreloaderCustom newStyle={{ height: '100vh', with: '60%', maxWidth: window.innerWidth < 960 ? '100%' : '490px'}}/>
                    }
                    <button 
                        className='sign-in__form-btn-back restore-password__form-btn-back' 
                        onClick={() => navigate(-1)}
                    >
                        <img 
                            className='sign-in__form-btn-back-img' 
                            src={arrowLeft} 
                            alt='img'
                        />
                        <div className='sign-in__form-title'>{translations['logIn'][language]}</div>
                    </button>
                    <div className='restore-password__form'>
                        <div className='restore-password__form-title-wrap'>
                            <div className='restore-password__form-title'>{translations['newPassword'][language]}</div>
                            <div className='restore-password__desktop-form-sub-title'>{translations['startManaging'][language]}</div>
                        </div>
                        <div className='restore-password__form-input-wrap'>
                            <PasswordInput 
                                password={newPassword} 
                                setPassword={setNewPassword} 
                                label={translations['newPassword'][language]} 
                                errorPassword={isErrorPassword ? !newPassword?.length ? translations['enterPassword'][language] : newPassword === repeatedPassword ? translations['errorPassword2'][language] : !validatePassword(newPassword) ? translations['errorPassword'][language] : '' : ''}
                            />
                            <PasswordInput 
                                password={repeatedPassword} 
                                setPassword={setRepeatedPassword} 
                                label={translations['confirmNewPassword'][language]} 
                                handleKeyEnter={handleSave} 
                                errorPassword={(isErrorPassword && repeatedPassword !== newPassword) ? translations['passwordsNotMatch'][language] : ''}
                            />
                        </div>
                        
                        <div className='restore-password__form-btn-wrap'>
                            <div className='restore-password__form-btn--wrap'>
                                <button 
                                    className='mainBtnDark mainBtn--whidth' 
                                    onClick={handleSave}
                                >
                                    {translations['restore'][language]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RestorePasswordView;