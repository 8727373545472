import './CoachingPlanCard.css';
import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import noPhotos from '../../assets/noPhotos.svg';
import clock from '../../assets/clock.svg';
import { useDispatch } from 'react-redux';
import { setUserExercise } from '../../store/homeSlice';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import playImg from '../../assets/playImg.svg';
import ReactPlayer from 'react-player';

function CoachingPlanCard({coachingExercise, index}) {
    const language = useSelector(state => state.homeSlice.language);
    const userTraining = useSelector(state => state.homeSlice.userTraining);
    const dispatch = useDispatch();
    const location = useLocation();

    const handleActiveSets = () => {
        let res = localStorage.getItem("localExercisesLocation")
        if (res?.length) {
            let resArr = JSON.parse(res)
            if (resArr?.length) {
                resArr = resArr.filter((el) => el.pathname === location.pathname + '/' + coachingExercise._id)
                if (resArr?.length) {
                    return resArr[0].activeNumber_sets
                } else {
                    return '1'
                }
            } else {
                return '1'
            }
        } else {
            return '1'
        }
    }

    const handleCheck = (e) => {
        if (userTraining.active_exercise_id === coachingExercise._id && userTraining.active_exercise_index === index) {
            dispatch(setUserExercise(coachingExercise))
        } else {
            e.preventDefault(); 
        }
    };

    return (
        <NavLink 
            to={`${coachingExercise._id}?activeNumber_sets=${handleActiveSets()}`} 
            onClick={handleCheck}
            className='coaching-plan-card'
        >
            <div className='coaching-plan-card__img-wrap'>
                {
                    !!coachingExercise.name?.length ?
                        <>
                            {
                                !!coachingExercise?.preview?.length ?
                                    <>
                                        <img 
                                            className={`coaching-plan-card__img ${!coachingExercise?.preview?.length ? 'coaching-plan-card__img--no-photos' : ''}`} 
                                            src={coachingExercise?.preview?.length ? coachingExercise?.preview : noPhotos} 
                                            alt='img'
                                        />
                                        {
                                            (!!coachingExercise?.preview?.length && !!coachingExercise?.video?.length) &&
                                                <img 
                                                    className='coaching-plan-card__video-img-play' 
                                                    src={playImg} 
                                                    alt='img'
                                                />
                                        }
                                    </>
                                    :
                                    <>
                                        <ReactPlayer 
                                            className="coaching-plan-card__video"
                                            url={coachingExercise?.video} 
                                            controls={false}
                                            light={true}
                                            config={{
                                                youtube: {
                                                playerVars: { modestbranding: 1, rel: 0, showinfo: 0 },
                                                embedOptions: { host: 'https://www.youtube.com', embedPath: '/embed/' },
                                                preload: false,
                                                xhrContext: { headers: { 'Access-Control-Allow-Origin': '*' } },
                                                },
                                            }}
                                            preload="metadata"
                                            playing={false}
                                        />
                                        <img 
                                            className='coaching-plan-card__video-img-play' 
                                            src={playImg} 
                                            alt='img'
                                        />
                                    </>
                            }
                        </>
                        :
                        <img 
                            className='coaching-plan-card__img-clock' 
                            src={clock} 
                            alt='img'
                        />
                }
            </div>
            <div className='coaching-day-card__title--wrap'>
                <div className='coaching-plan-card__title-wrap'>
                    <div className='coaching-plan-card__title'>{!!coachingExercise.name?.length ? coachingExercise.name : 'Rest time'}</div>
                    <div className='coaching-plan-card__sub-title'>{coachingExercise.time} {translations['minutes'][language]}</div>
                </div>
                {
                    (userTraining.active_exercise_id === coachingExercise._id && userTraining.active_exercise_index === index) &&
                        <button className='coaching-day-card__btn'>{translations['run'][language]}</button>
                }
            </div>
        </NavLink>
    );
}

export default memo(CoachingPlanCard);