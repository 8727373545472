import './CoachingRest.css';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setIsModalFinished, setShowMessageObj, setUserTraining } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import { fetchRequest } from '../../helpers/Utils';

function CoachingRest() {
    const language = useSelector(state => state.homeSlice.language);
    const userTraining = useSelector(state => state.homeSlice.userTraining);
    const userExercise = useSelector(state => state.homeSlice.userExercise);
    const user = useSelector(state => state.homeSlice.user);
    const [isActive, setIsActive] = useState(false);
    const [isFinishedDay, setIsFinishedDay] = useState(false);
    const [isBtnLoader, setIsBtnLoader] = useState(false);
    const [isFinishedExercise, setIsFinishedExercise] = useState(false);
    const [activeIndex, setActiveIndex] = useState(userTraining.active_exercise_index ? userTraining.active_exercise_index : 0);
    const [timer, setTimer] = useState(userExercise.time * 60);
    const { coachingDayId } = useParams();
    const { coachingPlanId } = useParams();
    const { coachingId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let token = localStorage.getItem('token-user');
    
    useEffect(() => {
        if (userTraining._id?.length) {
            setActiveIndex(userTraining.active_exercise_index ? userTraining.active_exercise_index : 0);

            let localRestTime = localStorage.getItem('localRestTime')
            let resArr = JSON.parse(localRestTime)
            if (resArr?.length) {
                let resObj = resArr.find((el) => el.pathname === location.pathname + '/' + userTraining.active_exercise_index)
                if (resObj?.pathname?.length) {
                    const elapsedTime = parseInt(parseInt(Date.now() - parseInt(resObj.time)) / 1000);
                    const newTime = Math.max(0, timer - elapsedTime);
                    if (newTime > 0) {
                        setTimer(newTime);
                        setIsActive(true);
                    } else {
                        setIsActive(false);
                        setTimer(0);
                        handleCheckingFinishedDay()
                    }
                } else {
                    setIsActive(false);
                    setIsFinishedDay(false);
                    setIsFinishedExercise(false);
                    setTimer(userExercise.time * 60);
                }
            }  else {
                setIsActive(false);
                setIsFinishedDay(false);
                setIsFinishedExercise(false);
                setTimer(userExercise.time * 60);
            }
        }
    }, [userTraining]);
    
    useEffect(() => {
        let interval = null;
        if (isActive && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
                if (timer === 1) {
                    setIsActive(false)
                }
            }, 1000);
            handleCheckingFinishedDay();
        } else if (!isActive && timer === 0) {
            let localRestTime = localStorage.getItem('localRestTime')
            let resArr = JSON.parse(localRestTime)
            if (resArr?.length) {
                let resObj = resArr.find((el) => el.pathname === location.pathname + '/' + activeIndex)
                if (resObj?.pathname?.length) {
                    setTimer(0)
                    setIsActive(false)
                    clearInterval(interval);
                    handleCheckingFinishedDay();
                }
            }
        }

        return () => clearInterval(interval);
    }, [isActive, timer]);

    const handleStartStop = () => {
        setIsActive(true);
        let res = localStorage.getItem("localRestTime")
        if (res?.length) {
            let resArr = JSON.parse(res)
            if (resArr.filter(el => el.pathname === location.pathname + '/' + activeIndex).length) {
                localStorage.setItem("localRestTime", JSON.stringify([...resArr.map(el => el.pathname === location.pathname + '/' + activeIndex ? {pathname: location.pathname + '/' + activeIndex, time: new Date().getTime()} : el)]));
            } else {
                localStorage.setItem("localRestTime", JSON.stringify([{pathname: location.pathname + '/' + activeIndex, time: new Date().getTime()}, ...resArr]));
            }
        } else {
            localStorage.setItem("localRestTime", JSON.stringify([{pathname: location.pathname + '/' + activeIndex, time: new Date().getTime()}]));
        }
    };

    const formatTime = timeInSeconds => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleFinished = () => {
        // логіка закінчення вправи (доробити запит на бек)
        if (userTraining._id?.length) {
            setIsBtnLoader(true)
            let data = {
                user_id: user._id,
                training_id: userTraining._id,
                coach_id: userTraining?.coach_id,
                assigned_id: userTraining?.assigned_id,
            }
            fetchRequest('POST', `/assigned/finish-exercise?token=${token}`, data)
                .then(res => {
                    if (res?.success && res?.data) {
                        dispatch(setUserTraining({ ...userTraining, active_exercise_id: res?.data.active_exercise_id, active_exercise_index: res?.data.active_exercise_index  }));
                        navigate(`/user/${user._id}/${coachingId}/${coachingDayId}/${res?.data.active_exercise_id}?activeNumber_sets=${1}`)
                        handleRemovelocalRestTime()
                    } else {
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
                    }
                    setIsBtnLoader(false)
                })
        }
    } 

    const handleFinishedDay = () => {
        if (userTraining._id?.length) {
            // логіка закінчення дня (доробити запит на бек) 
            setIsBtnLoader(true)
            const index = userTraining.days.findIndex(element => element._id === coachingDayId);
            let test = index !== -1 && index === userTraining.days.length - 1
            let data = {
                user_id: user._id,
                training_id: userTraining._id,
                coach_id: userTraining?.coach_id,
                assigned_id: userTraining?.assigned_id,
            }
            if (isFinishedDay && test) {
                // логіка закінчення тренування (доробити запит на бек) 
                fetchRequest('POST', `/assigned/finish-training?token=${token}`, data)
                    .then(res => {
                        if (res?.success) {
                            dispatch(setIsModalFinished(true));
                            dispatch(setUserTraining({ ...userTraining, training_finished: true }));
                            navigate(`/user/${user._id}`)
                            handleRemovelocalRestTime()
                        } else {
                            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
                        }
                        setIsBtnLoader(false)
                    })
            } else {    
                fetchRequest('POST', `/assigned/finish-day?token=${token}`, data)
                    .then(res => {
                        if (res?.success && res?.data) {
                            dispatch(setIsModalFinished(true));
                            dispatch(setUserTraining({ ...userTraining, active_day_id: res?.data?.active_day_id, active_exercise_id: res?.data?.active_exercise_id, active_exercise_index: res?.data.active_exercise_index  }));
                            navigate(`/user/${user._id}/${coachingId}`)
                            handleRemovelocalRestTime()
                        } else {
                            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language]}));
                        }
                        setIsBtnLoader(false)
                    })
            }
        }
    }

    const handleRemovelocalRestTime = () => {
        let res = localStorage.getItem("localRestTime")
        if (res?.length) {
            let resArr = JSON.parse(res)
            if (resArr?.length) {
                resArr = [...resArr.filter((el) => el.pathname !== location.pathname + '/' + activeIndex)]
                if (resArr?.length) {
                    localStorage.setItem("localRestTime", JSON.stringify([...resArr]));
                } else {
                    localStorage.removeItem("localRestTime");
                }
            }
        } 
    }
        
    const handleCheckingFinishedDay = () => {
        if (userTraining?._id?.length) {
            let resDay = userTraining.days.find(el => el._id === coachingDayId)
            if (resDay?.exercises?.length) {
                let exerciseIndex = resDay?.exercises.findIndex(el => el._id === coachingPlanId)
                setIsFinishedDay(resDay.exercises.length - 1 === exerciseIndex ? true : false)
                setIsFinishedExercise(true)
            } else {
                setIsFinishedExercise(true)
            }
        }
    }

    return (
        <div className="coaching-rest">
            <div className="coaching-rest__main">
                <div className={`coaching-rest__hour ${isActive ? 'animated' : ''}`}></div>
                <div className="coaching-rest__time">{formatTime(timer)}</div>
                <div className="coaching-rest__title">{translations['restTime'][language]}</div>
            </div>
            {
                isFinishedExercise ?
                    <>
                        {
                            isFinishedDay ?
                                <button 
                                    className='mainBtnWhite coaching-rest__btn' 
                                    disabled={(isBtnLoader || isActive)}
                                    onClick={handleFinishedDay}
                                >
                                    {
                                        isBtnLoader ? 
                                            <span className='coaching-day-view__btn-loader'></span>
                                            :
                                            <span>{translations['finish'][language]}</span>
                                    }
                                </button>
                                :
                                <button 
                                    className='mainBtnWhite coaching-rest__btn' 
                                    disabled={(isBtnLoader || isActive)}
                                    onClick={handleFinished}
                                >
                                    {
                                        isBtnLoader ? 
                                            <span className='coaching-day-view__btn-loader'></span>
                                            :
                                            <span>{translations['complete'][language]}</span>
                                    }
                                </button>
                        }
                    </>
                    :
                    <button 
                        className='mainBtnDark' 
                        disabled={isActive}
                        onClick={handleStartStop}
                    >
                        {translations['start'][language]}
                    </button>
            }
        </div>
    );
}

export default memo(CoachingRest);
