import './CoachingDayCard.css';
import { useState, memo } from 'react';
import { NavLink } from 'react-router-dom';
import man from '../../assets/man.svg';
import cupImg from '../../assets/cupImg.svg';
import blockImg from '../../assets/blockImg.svg';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';

function CoachingDayCard({coachingDay, setIsModalTimeDifference}) {
    const language = useSelector(state => state.homeSlice.language);
    const userTraining = useSelector(state => state.homeSlice.userTraining);
    const [test, setTest] = useState(userTraining.active_day_id === coachingDay._id ? true : false);

    const handleTimeDay = (obj) => {
        let res = 0
        if (obj.exercises?.length) {
            res = obj.exercises.reduce((acc, el) => acc + el.time, 0)
        }
        return res
    }

    const handleIsBeforeIndex = () => {
        const indexId = userTraining.days.findIndex(obj => obj._id === coachingDay._id);
        const indexActivId = userTraining.days.findIndex(obj => obj._id === userTraining.active_day_id);
        if (indexId === -1 || indexActivId === -1) {
            console.error('One of the provided IDs does not exist in the objects array.');
            return null;
        }
      
        return indexId < indexActivId ? true : false;
    }

    const handleCheck = (e) => {
        if (userTraining.active_day_id !== coachingDay._id) {
            e.preventDefault(); 
            return
        } 
        if (userTraining.finish_day_at && handleCheckActiveDay(userTraining.finish_day_at)) {
            setIsModalTimeDifference(true)
            e.preventDefault(); 
            return
        }
    };

    const handleCheckActiveDay = (date) => {
        return (new Date(date).getTime() + 8 * 60 * 60 * 1000) > new Date().getTime();
    }

    return (
        <NavLink 
            to={`${coachingDay._id}`} 
            className={`coaching-day-card`} 
            onClick={handleCheck}
        >
            <div className='coaching-day-card__img-wrap'>
                <img 
                    className={`coaching-day-card__img ${!test && !handleIsBeforeIndex() ? 'coaching-day-card__img--finnish' : ''}`} 
                    src={test ? man : handleIsBeforeIndex() ? cupImg : blockImg } 
                    alt='img'
                />
            </div>
            <div className='coaching-day-card__title--wrap'>
                <div className='coaching-day-card__title-wrap'>
                    <div className='coaching-day-card__title'>{coachingDay.name}</div>
                    <div className='coaching-day-card__sub-title'>
                        <span>{coachingDay.exercises?.length}&nbsp;{translations['exercises2'][language]}&nbsp;-</span>
                        <span>{handleTimeDay(coachingDay)}&nbsp;{translations['minutes'][language]}</span>
                    </div>
                </div>
                {
                    test &&
                        <button className='coaching-day-card__btn'>{translations['run'][language]}</button>
                }
            </div>
        </NavLink>
    );
}

export default memo(CoachingDayCard);