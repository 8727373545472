import './CountdownDayTimer.css';
import React, { useState, useEffect } from 'react';

function CountdownDayTimer({ setIsModalTimeDifference, dateTimer=null }) {
    const [timer, setTimer] = useState(dateTimer || null);

    useEffect(() => {
        let timerId = null
        let s = dateTimer

        if (s > 0) {
            timerId = setInterval(() => {
                if (s > 0) {
                    s = s - 1
                    setTimer(s)
                } else {
                    setTimer(0)
                    setIsModalTimeDifference(false)
                    clearInterval(timerId)
                }
            }, 1000);
        } else {
            setTimer(0)
            setIsModalTimeDifference(false)
        }

        return () => clearInterval(timerId);
    }, []);

    const formatTime = timeInSeconds => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='countdown-timer'>
            {formatTime(timer)}
        </div>
    );
}

export default CountdownDayTimer;
