import './AboutView.css';
import aboutBg from '../../assets/aboutBg.png';
import { useState } from 'react';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import aboutImageOfPeopleMob from '../../assets/aboutImageOfPeopleMob.png';
import aboutImageOfPeopleDes from '../../assets/aboutImageOfPeopleDes.png';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import TextInput from '../../components/TextInput/TextInput';
import { fetchRequest } from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import PageMeta from '../../components/PageMeta/PageMeta';
import { pageMeta } from '../../pageMeta';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';

function AboutView() {
    const language = useSelector(state => state.homeSlice.language);
    const [isModalForm, setIsModalForm] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const dispatch = useDispatch();

    const handleIsModalForm = () => {
        if (name?.length) {
            let data = {
                user_name: name,
                email: email,
                text: text,
            }
            fetchRequest('POST', `/contact-form`, data)
                .then(res => {
                    if (res?.success) {
                        dispatch(setShowMessageObj({ open: true, status: 'success', message: 'The form has been sent' }));
                    } else {
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: 'An error occurred. Please try again later'}));
                    }
                    setIsModalForm(false);
                    handleCleanInput();
                })
        } else {
            setIsModalForm(false);
            handleCleanInput();
        }
    }
    
    const handleCleanInput = () => {
        setText('');
        setName('');
        setEmail('');
    }

    return (
        <div className="about-view-wrap">
            <PageMeta {...pageMeta['AboutView']} />
             {
                isModalForm && 
                    <ModalWindow 
                        title={translations['contactUs'][language]} 
                        handleClick={handleIsModalForm} 
                        rightBtn={translations['send'][language]}
                    >
                        <div className='modal-window__modal-input-wrap'>
                            <TextInput 
                                setValue={setName} 
                                value={name} 
                                label={translations['yourName'][language]}
                            />
                            <TextInput 
                                setValue={setEmail} 
                                value={email} 
                                label='Email'
                            />
                            <TextInput 
                                setValue={setText} 
                                value={text} 
                                label={translations['yourMessage'][language]} 
                                rows={4}
                            />
                        </div>
                    </ModalWindow>
            }
            <div className='home-view__main-wrap'>
                <img 
                    className='home-view__main-bg' 
                    src={aboutBg} 
                    alt='Background image'
                />
                <div className='home-view__main-title-wrap'>
                    <div className='home-view__main-title'>{translations['aboutUs'][language]}</div>
                    <div className='home-view__main-sub-title about-view__main-sub-title'>{translations['weWant'][language]}</div>
                </div>
                <button 
                    className='home-view__main-btn' 
                    onClick={() => setIsModalForm(true)}
                >
                    {translations['contactUs'][language]}
                </button>
            </div>
            <div className="about-view container">
                <div className='about-view__info-wrap'>
                    <InfoBlock
                        title={translations['aboutUs'][language]}
                        number={'WeNoCode'}
                        text={translations['infoBlockText'][language]}
                        btnText={translations['joinUs'][language]}
                        isAboutView={true}
                    />
                </div>
                <div className='about-view__project-wrap'>
                    <div className='about-view__project-title'>{translations['projectIdea'][language]}</div>
                    <div className='about-view__project-img-wrap'>
                        <img 
                            className='about-view__project-img-phone' 
                            src={aboutImageOfPeopleMob} 
                            alt='img'
                        />
                        <img 
                            className='about-view__project-img-desk' 
                            src={aboutImageOfPeopleDes} 
                            alt='img'
                        />
                    </div>
                    <div className='about-view__project-text-wrap'>
                        <div className='about-view__project-text'>{translations['ourProjectText1'][language]}</div>
                        <div className='about-view__project-text'>{translations['ourProjectText2'][language]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutView;