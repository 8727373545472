import './PreloaderCustom.css';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';

function PreloaderCustom({newStyle=null, isNewStyleLoader=false, progressVideo=null}) {
    const language = useSelector(state => state.userSlice.language);
    return (
        <div 
            className="loader-wrap" 
            style={newStyle}
        >
            <div className={`loader ${isNewStyleLoader ? 'loader--new-style' : ''}`}></div>
            {
                progressVideo > 0 &&
                    <div className='loader__progress-wrap'>
                        <div className='loader__progress'>
                            <div>{progressVideo}% {translations['downloadVideo'][language]}</div>
                            <div></div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default PreloaderCustom;