import './ScannerCamera.css';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchRequest } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import { setShowMessageObj } from '../../store/homeSlice';
import { useNavigate } from 'react-router-dom';
import { translations } from '../../localization';
import QrReader from 'react-qr-scanner'

function ScannerCamera() {
    const user = useSelector(state => state.homeSlice.user);
    const language = useSelector(state => state.homeSlice.language);
    const [isScanner, setIsScanner] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem('token-user')

    const handleScanError = (text) => {
        setIsScanner(false)
        console.log(text)
    }

    const handleScan = (result) => {
        if (!!result?.text?.length && !isScanner) {
            handleAddTrening(result.text)
        }
    };

    const handleAddTrening = (text) => {
        setIsScanner(true)
        let data = {
            user_id: user._id,
            training_id: text,
        }
        fetchRequest('POST', `/assigned/scan?token=${token}`, data)
            .then(res => {
                if (res?.success) {
                    dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['successfullyAdded'][language]}))
                    navigate(`/user/${user._id}`)
                } else {
                    dispatch(setShowMessageObj({ open: true, status: 'error', message: res?.statusCode === 401 ? translations['errorTrening1'][language] : translations['anErrorOccurred'][language]}))
                }
                setIsScanner(false)
            })
    }

    return (
        <div className='scanner-camera'>
            <QrReader
                onScan={handleScan}
                onError={(error) => handleScanError(error?.message)}
                delay = {1000} 
                constraints={{
                    video: { facingMode: 'environment' }
                }}
            />
        </div>
    );
}

export default memo(ScannerCamera);