import './InstructionView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchGetData } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import { setUserExercise } from '../../store/homeSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useParams } from 'react-router-dom';
import noPhotos2 from '../../assets/noPhotos2.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { translations } from '../../localization';
import ReactQuill from 'react-quill';

function InstructionView() {
    const language = useSelector(state => state.homeSlice.language);
    const userExercise = useSelector(state => state.homeSlice.userExercise);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const dispatch = useDispatch();
    const { coachingPlanId } = useParams();
    let token = localStorage.getItem('token-user')
    const [testInstruction, setTestInstruction] = useState(false);
    const [testMistakes, setTestMistakes] = useState(false);

    useEffect(() => {
        if (!userExercise?._id?.length) {
            handleGetExercise()
        } else {
            handleTestInstruction(userExercise)
        }
        
    }, [])
    
    const handleOpenImage = () => {
        if (userExercise?.image?.length) {
            setIsOpenImage(true);
        }
    }
    
    const handleTestInstruction = (obj) => {
        setTestInstruction((obj?.instruction_text?.ops?.length && obj?.instruction_text?.ops[0].insert?.length > 2) ? true :false)
        setTestMistakes((obj?.instruction_mistakes?.ops?.length && obj?.instruction_mistakes?.ops[0].insert?.length > 2) ? true :false)
    }

    const handleGetExercise = () => {
        setIsPreloader(true);
        fetchGetData(`/exercises/${coachingPlanId}?token=${token}`)
            .then(res => {
                if (res?.success && res?.data) {
                    dispatch(setUserExercise(res.data))
                    handleTestInstruction(res.data)
                }
                setIsPreloader(false);
            })
    }

    return (
        <div className='instruction-view-wrap'>
            <PageMeta {...pageMeta['InstructionView']} />
            {
                isPreloader && 
                    <PreloaderCustom 
                        newStyle={{height: `100vh`, left: `0`, top: '0'}} 
                        isNewStyleLoader={true}
                    />
            }
            {
                isOpenImage && 
                    <ModalWindow 
                        title={''} 
                        handleClick={() => setIsOpenImage(false)} 
                        notBtn={true}
                    >
                        <img 
                            className='instruction-view__modal-img' 
                            src={userExercise?.image?.length ? userExercise?.image : noPhotos2} 
                            alt='img'
                        /> 
                        {
                            !!userExercise.instruction_title &&
                                <div className='instruction-view__modal-name'>{userExercise.instruction_title}</div>
                        }
                    </ModalWindow>
            }
            <div className='instruction-view container'>
                {
                    (!userExercise?.image?.length && !testInstruction && !testMistakes && !userExercise?.instruction_title?.length) ?
                        <div className='instruction-view__error-text'>{translations['noInstruction'][language]}</div>
                        :
                        <>
                            <h3 className='instruction-view__sub-title'>{translations['equipment'][language]}</h3>
                            <div className='instruction-view__equipment-wrap'>
                                <button 
                                    className='instruction-view__equipment-img-wrap' 
                                    onClick={handleOpenImage}
                                >
                                    <img 
                                        className={`instruction-view__equipment-img ${!userExercise?.image?.length ? 'instruction-view__equipment-img--no-image' : ''}`} 
                                        src={userExercise?.image?.length ? userExercise?.image : noPhotos2} 
                                        alt='img'
                                    />
                                </button>
                                <div className='instruction-view__equipment-name'>{userExercise?.instruction_title}</div>
                            </div>
                            {
                                testInstruction &&
                                    <>
                                        <h3 className='instruction-view__sub-title'>{translations['stepByStep'][language]}</h3>
                                        <ReactQuill
                                            className='instruction-view__text-wrap'
                                            value={userExercise.instruction_text}
                                            readOnly={true}
                                            theme="bubble"
                                        />
                                    </>
                            }
                            {
                                testMistakes &&
                                    <>
                                        <h3 className='instruction-view__sub-title'>{translations['typicalMistakes'][language]}</h3>
                                        <ReactQuill
                                            className='instruction-view__text-wrap'
                                            value={userExercise.instruction_mistakes}
                                            readOnly={true}
                                            theme="bubble"
                                        />
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    );
}

export default InstructionView;