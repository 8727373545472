import './CoachingView.css';
import CoachingDayCard from '../../components/CoachingDayCard/CoachingDayCard';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchGetData } from '../../helpers/Utils';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserTraining } from '../../store/homeSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import CountdownDayTimer from '../../components/CountdownDayTimer/CountdownDayTimer';
import { translations } from '../../localization';

function CoachingView() {
    const userTraining = useSelector(state => state.homeSlice.userTraining);
    const language = useSelector(state => state.homeSlice.language);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isModalTimeDifference, setIsModalTimeDifference] = useState(false);
    const { coachingId } = useParams();
    const { userId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem('token-user')

    useEffect(() => {
        if (!userTraining?._id?.length) {
            handleGetTraining()
        }
    }, [])
    
    const handleGetTraining = () => {
        setIsPreloader(true);
        fetchGetData(`/trainings/${coachingId}?token=${token}`)
            .then(res => {
                if (res?.success && res?.data) {
                    dispatch(setUserTraining(res.data))
                } else {
                    navigate(`/user/${userId}/not-found`)
                }
                setIsPreloader(false);
            })
    }

    function hendleDifferenceInSeconds(date1, date2) {
        const diffInMilliseconds = Math.abs(new Date(date1) - new Date(date2));
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        return diffInSeconds;
    }

    return (
        <div className="coaching-view-wrap">
            <PageMeta {...pageMeta['CoachingView']} />
            {
                isPreloader && 
                    <PreloaderCustom 
                        newStyle={{height: `100vh`, left: `0`, top: '0'}} 
                        isNewStyleLoader={true}
                    />
            }
            {
                isModalTimeDifference && 
                    <ModalWindow 
                        title={''} 
                        handleClick={() => setIsModalTimeDifference(false)} 
                        notBtn={true}
                    >
                        <div className='coaching-view__modal-text-wrap'>
                            <div className='coaching-rest__hour animated'></div>
                            <div className='coaching-view__modal-text'>{translations['canBeStarted'][language]}</div>
                            <div className='coaching-view__modal-text'>
                                <CountdownDayTimer 
                                    setIsModalTimeDifference={setIsModalTimeDifference} 
                                    dateTimer={hendleDifferenceInSeconds((new Date(userTraining.finish_day_at).getTime() + 8 * 60 * 60 * 1000), new Date().getTime())}
                                />
                            </div>
                        </div>
                    </ModalWindow>
            }
            <div className="coaching-view container">
                <div className="coaching-view__day-wrap">
                    {
                        !!userTraining?.days?.length && userTraining?.days.map(el => (
                            <CoachingDayCard 
                                coachingDay={el} 
                                setIsModalTimeDifference={setIsModalTimeDifference} key={el._id} 
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default CoachingView;